import dayjs from 'dayjs';
import logo from '../images/logo-pdf.png';
import {lightenColor, moneyFormatter, numberFormatter, toLocaleString} from './';

function exportDashboard(charts, company, data, dataAreas, equipments, startDate, endDate) {
    return new Promise((resolve, reject) => {
        if (charts.length === 0 || !company || !data || !dataAreas || !equipments || !startDate || !endDate) {
            return reject();
        }

        import('../fonts').then(async ({PoppinsRegular, PoppinsBold}) => {
            const {jsPDF} = await import('jspdf');
            const {applyPlugin} = await import('jspdf-autotable');

            applyPlugin(jsPDF);

            const doc = new jsPDF();

            doc.addFileToVFS('Poppins-normal.ttf', PoppinsRegular);
            doc.addFont('Poppins-normal.ttf', 'Poppins', 'normal');

            doc.addFileToVFS('Poppins-bold.ttf', PoppinsBold);
            doc.addFont('Poppins-bold.ttf', 'Poppins', 'bold');

            doc.setFont('Poppins', 'bold');
            doc.setFontSize(18);

            doc.text('Relatório de Consumo', 105, 10, {align: 'center'}, null);
            doc.setProperties({
                title: `Relatório de Consumo – ${company} – ${startDate} - ${endDate}`,
            });

            doc.setFontSize(12);
            doc.text('Empresa:', 15, 28);
            doc.setFont('Poppins', 'normal');
            doc.text(company, 37, 28);

            doc.setFont('Poppins', 'bold')
            doc.text('Custo Total:', 15, 34);
            doc.setFont('Poppins', 'normal');
            doc.text(moneyFormatter(data.cost), 43, 34);

            doc.setFont('Poppins', 'bold')
            doc.text('Total Consumido:', 15, 40);
            doc.setFont('Poppins', 'normal');
            doc.text(toLocaleString(data.volume / 1000) + 'L', 55, 40);

            doc.setFont('Poppins', 'bold')
            doc.text('Período do Consumo:', 15, 46);
            doc.setFont('Poppins', 'normal');
            doc.text(`${startDate} - ${endDate}`, 63, 46);

            doc.setFont('Poppins', 'bold')
            doc.text('Data e Hora da Exportação:', 15, 52);
            doc.setFont('Poppins', 'normal');
            doc.text(dayjs().format('DD/MM/YYYY HH:mm:ss'), 76, 52);

            doc.setFont('Poppins', 'bold');
            doc.setFontSize(16);

            const {Chart} = await import('chart.js/auto');
            const {default: ChartDataLabels} = await import('chartjs-plugin-datalabels');
            const {default: ChartAnnotation} = await import('chartjs-plugin-annotation');

            Chart.register(ChartAnnotation);
            Chart.register(ChartDataLabels);
            Chart.defaults.animation = false;
            Chart.defaults.layout.padding = 0;
            Chart.defaults.plugins.legend.display = false;
            Chart.defaults.font.family = 'Poppins';
            Chart.defaults.plugins.datalabels.color = '#000';
            Chart.defaults.plugins.datalabels.textAlign = 'center';

            let remainderY = 245;

            const plugin = {
                beforeDraw: (chart) => {
                    const {ctx} = chart;
                    ctx.save();
                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, chart.width, chart.height);
                    ctx.restore();
                },
            };

            const ctx = document.getElementById('chart');
            const chartContainer = document.getElementById('chart-container');

            chartContainer.style.display = 'block';

            if (charts.cost) {
                const pageHeight = doc.internal.pageSize.getHeight();
                let start = pageHeight - remainderY + ((remainderY - 150 - 10) / 2);

                if (remainderY === pageHeight) {
                    doc.addPage();
                } else {
                    start -= 10;
                }

                doc.text('Custo dos lubrificantes', 105, start, {align: 'center'}, null);

                const chartData = {
                    labels: Object.keys(data.by_lubricant),
                    datasets: [{
                        fill: true,
                        data: Object.values(data.by_lubricant).map(lubricant => lubricant.cost),
                        backgroundColor: Object.keys(data.by_lubricant).map((lubricant, i, by_lubricant) => {
                            return lightenColor('#054578', 204 / by_lubricant.length * (i + 1));
                        }),
                    }]
                };

                chartContainer.style.width = '600px';
                chartContainer.style.height = '600px';

                const chart = new Chart(ctx, {
                    type: 'doughnut',
                    plugins: [plugin],
                    data: chartData,
                    options: {
                        cutout: '65%',
                        plugins: {
                            annotation: {
                                annotations: {
                                    dLabel: {
                                        type: 'doughnutLabel',
                                        content: [
                                            numberFormatter(data?.cost, true),
                                            'Total',
                                        ],
                                        font: [
                                            {
                                                size: 20,
                                                weight: 'bold',
                                            },
                                            {
                                                size: 12,
                                            }
                                        ],
                                        color: ['#000', '#5C5C5C']
                                    }
                                }
                            },
                            datalabels: {
                                font: {
                                    size: 18,
                                    weight: 'bold',
                                },
                                formatter: value => numberFormatter(value, true),
                            },
                            legend: {
                                display: true,
                                labels: {
                                    font: {
                                        size: 12,
                                    },
                                },
                            },
                        },
                    },
                });

                const canvasImage = ctx.toDataURL('image/jpeg', 1.0);

                doc.addImage(canvasImage, 'jpeg', 29.5, start + 10, 150, 150);

                chart.destroy();

                remainderY = pageHeight;
            }

            if (charts.reason) {
                const pageHeight = doc.internal.pageSize.getHeight();
                let start = pageHeight - remainderY + ((remainderY - 150 - 10) / 2);

                if (remainderY === pageHeight) {
                    doc.addPage();
                } else {
                    start -= 10;
                }

                doc.text('Razão do Consumo', 105, start, {align: 'center'}, null);

                const chartData = {
                    labels: [
                        'Complemento de nível habitual',
                        'Complemento de nível corretiva',
                        'Complemento de nível vazamento',
                        'Troca de óleo',
                    ],
                    datasets: [{
                        fill: true,
                        data: Object.values(data.by_reason),
                        backgroundColor: Array(4).fill(1).map((reason, i) => {
                            return lightenColor('#054578', 204 / 4 * (i + 1));
                        }),
                    }]
                };

                chartContainer.style.width = '600px';
                chartContainer.style.height = '600px';

                const chart = new Chart(ctx, {
                    type: 'doughnut',
                    plugins: [plugin],
                    data: chartData,
                    options: {
                        cutout: '65%',
                        plugins: {
                            datalabels: {
                                font: {
                                    size: 18,
                                    weight: 'bold',
                                },
                                formatter: value => `${numberFormatter(parseFloat((value / data.volume * 100).toFixed(2)))}%`,
                            },
                            legend: {
                                display: true,
                                labels: {
                                    font: {
                                        size: 12,
                                    },
                                },
                            },
                        },
                    },
                });

                const canvasImage = ctx.toDataURL('image/jpeg', 1.0);

                doc.addImage(canvasImage, 'jpeg', 29.5, start + 10, 150, 150);

                chart.destroy();
            }

            if (charts.volume) {
                doc.addPage('a4', 'landscape');

                const pageHeight = doc.internal.pageSize.getHeight();
                let start = (pageHeight - 135) / 2;

                doc.text('Volume de lubrificantes', 150, start, {align: 'center'}, null);

                const chartData = {
                    labels: Object.values(data.by_lubricant).map(lubricant => lubricant.acronym),
                    datasets: [{
                        fill: true,
                        data: Object.values(data.by_lubricant).map(lubricant => lubricant.volume / 1000),
                        backgroundColor: Object.keys(data.by_lubricant).map((lubricant, i, by_lubricant) => {
                            return lightenColor('#054578', 204 / by_lubricant.length * (i + 1));
                        }),
                    }]
                };

                chartContainer.style.width = '1080px';
                chartContainer.style.height = '540px';

                const chart = new Chart(ctx, {
                    type: 'bar',
                    plugins: [plugin],
                    data: chartData,
                    options: {
                        plugins: {
                            datalabels: {
                                anchor: 'end',
                                font: {
                                    size: 16,
                                },
                                formatter: value => `${numberFormatter(value)} L`,
                            },
                        },
                        scales: {
                            y: {
                                ticks: {
                                    callback: val => `${numberFormatter(val)} L`,
                                },
                            },
                        },
                    },
                });

                const canvasImage = ctx.toDataURL('image/jpeg', 1.0);

                doc.addImage(canvasImage, 'jpeg', 13.5, start + 10, 270, 135);

                chart.destroy();
            }

            if (charts.consumption) {
                doc.addPage('a4', 'landscape');

                const pageHeight = doc.internal.pageSize.getHeight();
                let start = (pageHeight - 135) / 2;

                doc.text('Consumo de Equipamentos', 150, start, {align: 'center'}, null);

                const chartData = {// eslint-disable-next-line
                    labels: Object.keys(data?.by_machines).map(machine => equipments.find(equipment => equipment.id == machine.slice(1)).name),
                    datasets: [
                        {
                            fill: true,
                            data: Object.values(data.by_machines).map(machine => machine.volume / 1000),
                            backgroundColor: '#bfdbf7',
                            yAxisID: 'yAxisA',
                            order: 1,
                        },
                        {
                            data: Object.values(data.by_machines).map(machine => machine.accumulated),
                            borderColor: '#577399',
                            backgroundColor: '#577399',
                            type: 'line',
                            yAxisID: 'yAxisB',
                            order: 0,
                        }
                    ]
                };

                chartContainer.style.width = '1080px';
                chartContainer.style.height = '540px';

                const chart = new Chart(ctx, {
                    type: 'bar',
                    plugins: [plugin],
                    data: chartData,
                    options: {
                        plugins: {
                            datalabels: {
                                anchor: 'start',
                                font: {
                                    size: 16,
                                },
                                formatter: (value, context) => {
                                    if (context.dataset.yAxisID === 'yAxisA') {
                                        return `${numberFormatter(value)} L`;
                                    }

                                    return `${numberFormatter(parseFloat(value.toFixed(2)))}%`;
                                },
                            },
                        },
                        scales: {
                            yAxisA: {
                                type: 'linear',
                                position: 'left',
                                ticks: {
                                    callback: val => `${numberFormatter(val)} L`,
                                },
                            },
                            yAxisB: {
                                type: 'linear',
                                position: 'right',
                                ticks: {
                                    callback: val => `${numberFormatter(val)}%`,
                                },
                            },
                        },
                    },
                });

                const canvasImage = ctx.toDataURL('image/jpeg', 1.0);

                doc.addImage(canvasImage, 'jpeg', 13.5, start + 10, 270, 135);

                chart.destroy();
            }

            if (charts.area) {
                doc.addPage('a4', 'landscape');

                const pageHeight = doc.internal.pageSize.getHeight();
                let start = (pageHeight - 135) / 2;

                doc.text('Área dos Lubrificantes', 150, start, {align: 'center'}, null);

                const chartData = {// eslint-disable-next-line
                    labels: dataAreas.map(area => area.area),
                    datasets: [
                        {
                            fill: true,
                            data: dataAreas.map(area => area.consumption),
                            backgroundColor: '#bfdbf7',
                            yAxisID: 'yAxisA',
                            order: 1,
                        },
                        {
                            data: dataAreas.map(area => area.cost),
                            borderColor: '#577399',
                            backgroundColor: '#577399',
                            type: 'line',
                            yAxisID: 'yAxisB',
                            order: 0,
                        }
                    ]
                };

                chartContainer.style.width = '1080px';
                chartContainer.style.height = '540px';

                const chart = new Chart(ctx, {
                    type: 'bar',
                    plugins: [plugin],
                    data: chartData,
                    options: {
                        plugins: {
                            datalabels: {
                                anchor: 'start',
                                font: {
                                    size: 16,
                                },
                                formatter: (value, context) => {
                                    if (context.dataset.yAxisID === 'yAxisA') {
                                        return `${numberFormatter(value)} L`;
                                    }

                                    return numberFormatter(value, true);
                                },
                            },
                        },
                        scales: {
                            yAxisA: {
                                type: 'linear',
                                position: 'left',
                                ticks: {
                                    callback: val => `${numberFormatter(val)} L`,
                                },
                            },
                            yAxisB: {
                                type: 'linear',
                                position: 'right',
                                ticks: {
                                    callback: val => numberFormatter(val, true),
                                },
                            },
                        },
                    },
                });

                const canvasImage = ctx.toDataURL('image/jpeg', 1.0);

                doc.addImage(canvasImage, 'jpeg', 13.5, start + 10, 270, 135);

                chart.destroy();
            }

            chartContainer.style.display = 'none';

            doc.setFontSize(8);
            doc.setFont('Poppins', 'normal');

            const totalPages = doc.internal.getNumberOfPages();
            for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                doc.addImage(logo, 'PNG', 5, doc.internal.pageSize.getHeight() - 8.5, 10.25, 5.75);
                doc.text(`Página ${i} de ${totalPages}`, doc.internal.pageSize.getWidth() - 25, doc.internal.pageSize.getHeight() - 5)
            }

            doc.save(`Relatório de Consumo – ${company} – ${startDate} - ${endDate}.pdf`);

            resolve();
        });
    });
}

export default exportDashboard;

export {exportDashboard};