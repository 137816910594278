import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    TextField,
} from '@mui/material';
import {useAuth} from '../hooks';
import BtnClose from './BtnClose';
import {AlertContext, api} from '../services';
import {Controller, useForm} from 'react-hook-form';
import {useContext, useEffect, useState} from 'react';
import monitorTypes from '../dictonarys/monitorTypes';


function CreateOrEditMonitor({type, monitor, onClose, fullScreen, loadMonitors}) {
    const {handleLogout} = useAuth();
    const text = monitorTypes[type].text;
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const prefixes = {
        lubricator: 'LBR',
        breather: 'RPR',
        'level-display': 'NVL',
    };
    const prefix = prefixes[type];

    const {
        reset,
        control,
        register,
        setError,
        handleSubmit,
        formState: {errors},
    } = useForm();

    function onSubmit(data) {
        const total_capacity = data.total_capacity;
        const current_capacity = data.current_capacity;
        let form = {};

        if (type === 'lubricator') {
            if (monitor?.id) {
                if (current_capacity > total_capacity) {
                    setError('current_capacity', {
                        type: 'error',
                        message: 'A capacidade atual não pode ser maior que a capacidade total.',
                    });
                    setLoading(false);
                    return;
                }

                form = {serial: data.serial, total_capacity, current_capacity, duration: data.duration}
            } else {
                form = {serial: data.serial, total_capacity}
            }
        } else {
            form = {serial: data.serial};
        }

        let apiMethod = api.post;
        let apiEndpoint = `/${type}s`;

        if (monitor?.id) {
            apiMethod = api.put;
            apiEndpoint += '/' + monitor.id;
        }

        setLoading(true);

        apiMethod(apiEndpoint, form)
            .then(response => {
                onClose();
                newAlert(response?.data?.message, 'success');
                loadMonitors();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) {
                    Object.entries(error.response?.data?.errors)
                        .forEach(([key, message]) => {
                            setError(key, {
                                type: 422,
                                message: message[0],
                            });
                        });
                } else {
                    const action = monitor?.id ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao tentar ${action} o ${text}. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (!monitor.open) return;

        reset({
            serial: monitor?.serial ?? '',
            duration: monitor?.duration ?? '',
            total_capacity: monitor?.total_capacity ?? '',
            current_capacity: monitor?.current_capacity ?? '',
        })
        // eslint-disable-next-line
    }, [monitor]);

    return (
        <Dialog
            maxWidth="xs"
            open={Boolean(monitor?.open)}
            onClose={onClose}
            fullScreen={fullScreen}
            slotProps={{
                paper: {
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit),
                },
            }}
        >
            <DialogTitle>
                <Box>
                    {monitor?.id ? `Editar ${prefix}-${monitor?.serial}` : `Novo ${text}`}
                </Box>
                <BtnClose onClick={onClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    autoFocus
                    fullWidth
                    type="text"
                    margin="normal"
                    label="Nº de série"
                    error={Boolean(errors?.serial)}
                    helperText={errors?.serial?.message}
                    slotProps={{
                        htmlInput: {
                            className: 'uppercase',
                        },
                        input: {
                            startAdornment: <InputAdornment position="start">GETLUB-{prefix}-</InputAdornment>,
                        },
                    }}
                    {...register('serial', {
                        required: `O número de série do ${text} é obrigatório.`,
                    })}
                />
                {
                    type === 'lubricator' && (
                        <>
                            {
                                monitor?.duration && (
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        type="number"
                                        label="Duração"
                                        className="mt-4"
                                        error={Boolean(errors?.duration)}
                                        helperText={errors?.duration?.message}
                                        slotProps={{
                                            input: {
                                                endAdornment: <InputAdornment position="end">mês(es)</InputAdornment>,
                                            },
                                        }}
                                        {...register('duration', {
                                            valueAsNumber: true,
                                            required: 'A duração do lubrificador é obrigatória.',
                                            min: {
                                                value: 1,
                                                message: 'A duração do lubrificador deve ser de 1 a 12 meses.',
                                            },
                                            max: {
                                                value: 12,
                                                message: 'A duração do lubrificador deve ser de 1 a 12 meses.',
                                            }
                                        })}
                                    />
                                )
                            }
                            <Controller
                                name="total_capacity"
                                control={control}
                                defaultValue={null}
                                rules={{required: 'A capacidade total é obrigatória.',}}
                                render={({field}) => (
                                    <Autocomplete
                                        fullWidth
                                        options={[60, 150, 250]}
                                        value={field.value}
                                        noOptionsText="Nenhuma opção"
                                        getOptionLabel={option => option ? `${option} g` : ''}
                                        onChange={(event, value) => field.onChange(value)}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                margin="normal"
                                                name="total_capacity"
                                                label="Capacidade Total"
                                                error={Boolean(errors?.total_capacity)}
                                                helperText={errors?.total_capacity?.message}
                                            />
                                        )}
                                    />
                                )}
                            />
                            {
                                monitor?.id !== undefined && (
                                    <TextField
                                        fullWidth
                                        type="number"
                                        margin="normal"
                                        label="Capacidade Atual"
                                        error={Boolean(errors?.current_capacity)}
                                        helperText={errors?.current_capacity?.message}
                                        {...register('current_capacity', {
                                            valueAsNumber: true,
                                            required: 'A capacidade atual é obrigatória.',
                                            min: {
                                                value: 0,
                                                message: 'A capacidade atual precisa ser um número inteiro não negativo.',
                                            },
                                        })}
                                        slotProps={{
                                            input: {
                                                endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                            },
                                            htmlInput: {
                                                min: 1,
                                                step: 0.01,
                                            },
                                        }}
                                    />
                                )
                            }
                        </>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    className="px-12"
                    loading={loading}
                    disabled={Object.keys(errors).length > 0}
                >
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateOrEditMonitor;

export {CreateOrEditMonitor};