import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    TextField,
    useMediaQuery,
    useTheme
} from '@mui/material';
import BtnClose from './BtnClose';
import Grid from '@mui/material/Grid2';
import {useAuth} from '../hooks/useAuth';
import {AlertContext, api} from '../services';
import {Controller, useForm} from 'react-hook-form';
import {useContext, useEffect, useState} from 'react';

function DefineOrEditConsumption({consumption, setConsumption, loadConsumptions}) {
    const theme = useTheme();
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const {
        reset,
        control,
        register,
        setError,
        handleSubmit,
        formState: {errors},
    } = useForm();

    function handleClose() {
        if (window.location.hash) {
            window.location.href = '#';
        }

        setConsumption({...consumption, open: false});
    }

    function onSubmit(data) {
        setLoading(true);

        api.put(`/consumptions/${consumption?.data?.id}`, {
            quantity: data.quantity,
            reason: data.reason.code,
        })
            .then(response => {
                handleClose();
                newAlert(response?.data?.message, 'success');
                loadConsumptions();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) {
                    Object.entries(error.response?.data?.errors)
                        .forEach(([key, message]) => {
                            setError(key, {
                                type: 422,
                                message: message[0],
                            });
                        });
                } else {
                    const action = consumption?.data?.quantity ? 'alterar' : 'definir';

                    newAlert(error.response?.data?.message ?? `Erro ao tentar ${action} o consumo. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (consumption.open) {
            reset({
                quantity: consumption?.data?.quantity ?? '',
                reason: consumption?.data?.reason ?? {code: 'usual', label: 'Complemento de Nível Habitual',},

            });
        }
        // eslint-disable-next-line
    }, [consumption]);

    return (
        <Dialog
            maxWidth="md"
            open={Boolean(consumption?.open)}
            onClose={handleClose}
            fullScreen={fullScreen}
            slotProps={{
                paper: {
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit),
                },
            }}
        >
            <DialogTitle>
                <Box>
                    {consumption?.data?.quantity ? 'Alterar' : 'Definir'} Consumo
                </Box>
                <BtnClose onClick={handleClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container className="mt-3" rowSpacing={2} columnSpacing={4}>
                    <Grid size={{xs: 12, sm: 5}}>
                        <TextField
                            fullWidth
                            type="number"
                            margin="normal"
                            label="Quantidade reposta"
                            error={Boolean(errors?.quantity)}
                            helperText={errors?.quantity?.message}
                            {...register('quantity', {
                                valueAsNumber: true,
                                required: 'A quantidade reposta é obrigatória.',
                            })}
                            slotProps={{
                                htmlInput: {
                                    min: 1,
                                    step: 0.01,
                                },
                                input: {
                                    endAdornment: <InputAdornment position="end">ml</InputAdornment>,
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, sm: 7}}>
                        <Controller
                            name="reason"
                            control={control}
                            defaultValue={null}
                            rules={{required: 'A razão é obrigatória.'}}
                            render={({field}) => (
                                <Autocomplete
                                    fullWidth
                                    options={[
                                        {
                                            code: 'usual',
                                            label: 'Complemento de Nível Habitual',
                                        },
                                        {
                                            code: 'corrective',
                                            label: 'Complemento de Nível Corretiva',
                                        },
                                        {
                                            code: 'leak',
                                            label: 'Complemento de Nível Vazamento',
                                        },
                                        {
                                            code: 'change',
                                            label: 'Troca de Óleo',
                                        },
                                    ]}
                                    value={field.value}
                                    noOptionsText="Nenhuma razão"
                                    onChange={(event, value) => field.onChange(value)}
                                    isOptionEqualToValue={(option, value) => option.code === value.code}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            name="reason"
                                            label="Razão"
                                            margin="normal"
                                            error={Boolean(errors?.reason)}
                                            helperText={errors?.reason?.message}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    className="px-12"
                    loading={loading}
                    disabled={Object.keys(errors).length > 0}
                >
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DefineOrEditConsumption;

export {DefineOrEditConsumption};