import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    TextField,
    Typography
} from '@mui/material';
import {useAuth} from '../hooks';
import BtnClose from './BtnClose';
import {phoneMask} from '../utils';
import Grid from '@mui/material/Grid2';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useState} from 'react';
import {BtnShowPassword, FindCompany, FindFolder} from './index';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function CreateOrEditUser({loadUsers, manageUser, onClose}) {
    const {newAlert} = useContext(AlertContext);
    const [count, setCount] = useState(1);
    const [roles, setRoles] = useState([]);
    const {handleLogout, setLoadingBackdrop} = useAuth();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const {
        watch,
        reset,
        control,
        register,
        setError,
        setValue,
        handleSubmit,
        formState: {errors},
    } = useForm({
        defaultValues: {
            role: [],
            folder: [],
            company: [],
        },
    });

    const {remove: removeRole} = useFieldArray({
        control,
        name: 'role',
    });

    const {remove: removeCompany} = useFieldArray({
        control,
        name: 'company',
    });

    const {remove: removeFolder} = useFieldArray({
        control,
        name: 'folder',
    });

    const role = watch('role');
    const folders = watch('folder');
    const companies = watch('company');
    const isAdmin = watch('is_admin');
    const phone_notifications = watch('phone_notifications');

    function handleMinusFolder() {
        setCount(count - 1);
        removeRole(count - 1);
        removeFolder(count - 1);
        removeCompany(count - 1);
    }

    function handlePhone(event) {
        const input = event.target;

        input.value = phoneMask(input.value);
    }

    function onSubmit(data) {
        setLoading(true);

        const phoneNumeric = data.phone.replace(/[^0-9]/g, '');

        if (data.password !== data.password_confirmation) {
            setError('password_confirmation', {
                type: 'password',
                message: 'A senha e confirmação não conferem.',
            })
            setLoading(false);
            return;
        }

        let apiMethod = api.post;
        let apiEndpoint = '/users';

        if (manageUser.id) {
            apiMethod = api.put;
            apiEndpoint += '/' + manageUser.id;
        }

        apiMethod(apiEndpoint, {
            name: data.name,
            email: data.email,
            email_notifications: data.email_notifications,
            phone: phoneNumeric,
            phone_notifications,
            password: data.password,
            password_confirmation: data.password_confirmation,
            is_admin: isAdmin,
            folders,
            role,
        })
            .then(response => {
                newAlert(response.data?.message, 'success');

                onClose();
                loadUsers();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) {
                    Object.entries(error.response?.data?.errors)
                        .forEach(([key, message]) => {
                            setError(key, {
                                type: 422,
                                message: message[0],
                            });
                        });
                } else {
                    const action = manageUser.id ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao ${action} o usuário. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        api.get('/roles/all')
            .then(response => setRoles(response.data?.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao carregar as funções. Tente novamente mais tarde.');
            })
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (!manageUser.id) {
            if (manageUser?.open) {
                reset({
                    name: '',
                    email: '',
                    email_notifications: true,
                    phone: '',
                    phone_notifications: true,
                    is_admin: false,
                    company: [],
                    folder: [],
                    role: [],
                });
            }

            return;
        }

        setLoadingBackdrop(true);

        api.get('/users/' + manageUser.id)
            .then(response => {
                response = response.data?.data;

                reset({
                    name: response?.name ?? '',
                    email: response?.email ?? '',
                    email_notifications: response?.email_notifications ?? true,
                    phone: phoneMask(response?.phone ?? ''),
                    phone_notifications: response?.phone_notifications ?? true,
                    is_admin: Boolean(response?.is_admin),
                    company: response?.companies ?? [],
                    folder: response?.folders ?? [],
                    role: response?.roles ?? [],
                });

                if (!Boolean(response?.is_admin)) {
                    setCount(response?.folders?.length ?? 1);
                }
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar o usuário. Tente novamente mais tarde.');
                }
            })
            .finally(() => setLoadingBackdrop(false))
        // eslint-disable-next-line
    }, [manageUser.id]);

    return (
        <Dialog
            maxWidth="md"
            open={Boolean(manageUser?.open)}
            onClose={onClose}
            slotProps={{
                paper: {
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit),
                    autocomplete: 'off'
                },
            }}
        >
            <DialogTitle>
                <Box>
                    Detalhes da Conta
                </Box>
                <BtnClose onClick={onClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            autoFocus
                            fullWidth
                            type="text"
                            label="Nome"
                            margin="normal"
                            error={Boolean(errors?.name)}
                            helperText={errors?.name?.message}
                            {...register('name', {
                                required: 'O nome é obrigatório.',
                                minLength: {
                                    value: 3,
                                    message: 'O nome precisa ter pelo menos 3 caracteres.',
                                },
                            })}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            fullWidth
                            type="email"
                            label="E-mail"
                            margin="normal"
                            error={Boolean(errors?.email)}
                            helperText={errors?.email?.message}
                            {...register('email', {
                                required: 'O email é obrigatório.',
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: 'E-mail inválido.'
                                }
                            })}
                        />
                        <Controller
                            name="email_notifications"
                            control={control}
                            defaultValue={true}
                            render={({field}) => (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            onChange={e => field.onChange(e.target.checked)}
                                        />
                                    }
                                    label="Deseja receber notificações por e-mail"
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            fullWidth
                            label="Senha"
                            margin="normal"
                            type={showPassword ? 'text' : 'password'}
                            error={Boolean(errors?.password)}
                            helperText={errors?.password?.message}
                            slotProps={{
                                input: {
                                    endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                                },
                            }}
                            {...register('password', {
                                required: Boolean(!manageUser.id) ? 'A senha é obrigatória.' : false,
                                minLength: {
                                    value: 8,
                                    message: 'A senha precisa ter pelo menos 8 caracteres.',
                                },
                            })}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Confirme a senha"
                            type={showPassword ? 'text' : 'password'}
                            error={Boolean(errors?.password_confirmation)}
                            helperText={errors?.password_confirmation?.message}
                            slotProps={{
                                input: {
                                    endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                                },
                            }}
                            {...register('password_confirmation', {
                                required: Boolean(!manageUser.id) ? 'A confirmação de senha é obrigatória.' : false,
                                minLength: {
                                    value: 8,
                                    message: 'A confirmação de senha precisa ter pelo menos 8 caracteres.',
                                },
                            })}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            fullWidth
                            type="tel"
                            label="Telefone"
                            margin="normal"
                            onKeyUp={handlePhone}
                            error={Boolean(errors?.phone)}
                            helperText={errors?.phone?.message}
                            {...register('phone', {
                                required: phone_notifications ? 'O telefone é obrigatório se desejar receber notificações.' : false,
                                minLength: {
                                    value: 15,
                                    message: 'O telefone precisa ter 11 dígitos.',
                                },
                                maxLength: {
                                    value: 15,
                                    message: 'O telefone precisa ter 11 dígitos.',
                                },
                            })}
                        />
                        <Controller
                            name="phone_notifications"
                            control={control}
                            defaultValue={true}
                            render={({field}) => (
                                <FormControlLabel
                                    className="mr-0"
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={field.value}
                                            onChange={e => field.onChange(e.target.checked)}
                                        />
                                    }
                                    label="Deseja receber notificações por WhatsApp ou SMS"
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={12} className="mt-2">
                        <Typography className="text-xl font-medium -mb-3">
                            Permissões
                        </Typography>
                        <Divider className="flex justify-around" textAlign="right">
                            <Controller
                                name="is_admin"
                                control={control}
                                defaultValue={false}
                                render={({field}) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value}
                                                onChange={e => field.onChange(e.target.checked)}
                                            />
                                        }
                                        label="É administrador"
                                    />
                                )}
                            />
                        </Divider>
                    </Grid>
                    {
                        !isAdmin && (
                            <>
                                {
                                    [...Array(count)].map((x, i) => (
                                        <Grid size={12} key={i}>
                                            <Grid container spacing={2}>
                                                <Grid size={12}>
                                                    <FindCompany
                                                        name={`company.${i}`}
                                                        control={control}
                                                        company={companies[i] ?? null}
                                                        setFolder={value => setValue(`folder.${i}`, value)}
                                                    />
                                                </Grid>
                                                <Grid size={{xs: 12, md: 6}}>
                                                    <FindFolder
                                                        name={`folder.${i}`}
                                                        control={control}
                                                        folder={folders[i] ?? null}
                                                        company={companies[i] ?? null}
                                                    />
                                                </Grid>
                                                <Grid size={{xs: 12, md: 6}}>
                                                    <Controller
                                                        name={`role.${i}`}
                                                        control={control}
                                                        defaultValue={null}
                                                        render={({field}) => (
                                                            <Autocomplete
                                                                fullWidth
                                                                options={roles}
                                                                value={field.value}
                                                                loading={roles.length === 0}
                                                                loadingText="Carregando ..."
                                                                getOptionLabel={option => option.name}
                                                                noOptionsText="Nenhuma função encontrada"
                                                                onChange={(event, value) => field.onChange(value)}
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        fullWidth
                                                                        name="role"
                                                                        label="Função"
                                                                        margin="normal"
                                                                        error={Boolean(errors[`role.${i}`])}
                                                                        helperText={errors[`role.${i}`]?.message}
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                            {
                                                i + 1 !== count &&
                                                <Grid size={12}>
                                                    <Divider className="mt-6"/>
                                                </Grid>
                                            }
                                        </Grid>
                                    ))
                                }
                                <Grid size={12}>
                                    <Grid container justifyContent="center">
                                        <IconButton
                                            color="error"
                                            aria-label="Remover a última permissão"
                                            onClick={handleMinusFolder}
                                            disabled={count <= 1}
                                        >
                                            <RemoveIcon/>
                                        </IconButton>
                                        <IconButton
                                            color="success"
                                            aria-label="Adicionar mais uma permissão"
                                            onClick={() => setCount(count + 1)}
                                        >
                                            <AddIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    className="px-12"
                    loading={loading}
                    disabled={Object.keys(errors).length > 0}
                >
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateOrEditUser;

export {CreateOrEditUser};