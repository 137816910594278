import {
    Box,
    Button,
    Paper,
    Stack,
    Typography,
    Unstable_TrapFocus as TrapFocus,
    useMediaQuery,
    useTheme
} from '@mui/material';
import Fade from './Fade';
import env from 'react-dotenv';
import {useAuth} from '../hooks';
import {useLocation} from 'react-router';
import {AlertContext} from '../services';
import {useContext, useRef, useState} from 'react';

function DialogInstallation({open}) {
    const theme = useTheme();
    const local = useLocation();
    const {authenticated} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const installPrompt = useRef(null);
    const [request, setRequest] = useState(false);
    const shortScreen = useMediaQuery(theme.breakpoints.down(710));

    if (local.pathname === '/login') return null;

    function closeBanner() {
        setRequest(false);
        localStorage.setItem('requestInstallation', 'false');
    }

    window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault();

        installPrompt.current = e;

        if (localStorage.getItem('requestInstallation') !== 'false') {
            setRequest(true);
        }
    });

    window.addEventListener('appinstalled', () => newAlert('Aplicativo instalado com sucesso!', 'success'));

    return (
        <TrapFocus open disableAutoFocus disableEnforceFocus>
            <Fade
                open={open}
                in={request}
                appear={false}
                authenticated={authenticated}
            >
                <Paper
                    square
                    role="dialog"
                    aria-modal="false"
                    aria-label="Notificações Push"
                    variant="outlined"
                    tabIndex={-1}
                    sx={{
                        m: 0,
                        p: 2,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 1,
                        borderWidth: 0,
                        position: 'fixed',
                        borderTopWidth: 1,
                    }}
                >
                    <Stack
                        gap={2}
                        justifyContent="space-between"
                        direction={shortScreen ? 'column' : 'row'}
                    >
                        <Box className="flex-shrink">
                            <Typography fontWeight="bold">Instale o {env?.APP_TITLE}</Typography>
                            <Typography variant="body2">
                                Tenha acesso rápido e fácil ao nosso monitoramento.
                            </Typography>
                        </Box>
                        <Stack
                            gap={2}
                            direction="row"
                            className="xs:self-center sm:self-end"
                        >
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={closeBanner}
                            >
                                Agora não
                            </Button>
                            <Button
                                size="small"
                                onClick={async () => {
                                    const result = await installPrompt.current.prompt();

                                    if (result.outcome === 'dismissed') {
                                        newAlert('Instalação cancelada.');
                                    }

                                    closeBanner();
                                }}
                            >
                                Instalar
                            </Button>
                        </Stack>
                    </Stack>
                </Paper>
            </Fade>
        </TrapFocus>
    );
}

export default DialogInstallation;

export {DialogInstallation};