import {
    DialogInstallation,
    DialogNotifications,
    Header,
    ListAlerts,
    Main,
    SuspenseWrapper,
    VerifyEmailAlert
} from './components';
import {useState} from 'react';
import {NotificationsProvider} from './services';
import {CssBaseline, useMediaQuery, useTheme} from '@mui/material';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router';
import {RequirePermission} from './components/auth/RequirePermission';
import {RequireAdmin, RequireAuth, RequireGuest} from './components/auth';

function RouteList() {
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [openMobile, setOpenMobile] = useState(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const handleDrawerToggle = () => isMobile ? setOpenMobile(!openMobile) : setOpen(!open);
    const handleDrawerClose = () => isMobile ? setOpenMobile(false) : setOpen(false);

    return (
        <BrowserRouter
            future={{
                v7_startTransition: true,
                v7_relativeSplatPath: true,
            }}
        >
            <CssBaseline/>
            <NotificationsProvider>
                <Header
                    open={open}
                    isMobile={isMobile}
                    openMobile={openMobile}
                    handleDrawerToggle={handleDrawerToggle}
                    handleDrawerClose={handleDrawerClose}
                />
                <Main
                    open={open}
                    className="flex items-center flex-wrap lg:p-6 md:p-4 py-4 px-2 bg-grey-bg"
                >
                    <VerifyEmailAlert/>
                    <Routes>
                        <Route index path="/" element={
                            <Navigate to="../login"/>
                        }/>
                        <Route path="/login" element={
                            <RequireGuest>
                                <SuspenseWrapper name="Login" title="Login"/>
                            </RequireGuest>
                        }/>
                        <Route path="/my-account" element={
                            <RequireAuth>
                                <SuspenseWrapper name="MyAccount" title="Minha conta"/>
                            </RequireAuth>
                        }/>
                        <Route path="/notifications" element={
                            <RequireAuth>
                                <SuspenseWrapper name="Notifications" title="Notificações"/>
                            </RequireAuth>
                        }/>
                        <Route path="/home" element={
                            <RequireAuth>
                                <SuspenseWrapper name="Home" title="Página inicial"/>
                            </RequireAuth>
                        }/>
                        <Route path="/assets-tree" element={
                            <RequireAuth>
                                <SuspenseWrapper name="AssetsTree" title="Árvore de Ativos"/>
                            </RequireAuth>
                        }/>
                        <Route path="/lubricators" element={
                            <RequireAuth>
                                <SuspenseWrapper name="ListLubricators" title="Lubrificadores"/>
                            </RequireAuth>
                        }/>
                        <Route path="/breathers" element={
                            <RequireAuth>
                                <SuspenseWrapper name="ListBreathers" title="Respiros"/>
                            </RequireAuth>
                        }/>
                        <Route path="/level-displays" element={
                            <RequireAuth>
                                <SuspenseWrapper name="ListLevelDisplays" title="Visores de Nível"/>
                            </RequireAuth>
                        }/>
                        <Route path="/roles" element={
                            <RequireAuth>
                                <RequireAdmin>
                                    <SuspenseWrapper name="ListRoles" title="Permissões"/>
                                </RequireAdmin>
                            </RequireAuth>
                        }/>
                        <Route path="/lubricators/manage" element={
                            <RequireAuth>
                                <RequireAdmin>
                                    <SuspenseWrapper name="ManageLubricators" title="Lubrificador"
                                                     documentTitle="Gerenciar Lubrificadores"/>
                                </RequireAdmin>
                            </RequireAuth>
                        }/>
                        <Route path="/lubricators/:id" element={
                            <RequireAuth>
                                <SuspenseWrapper name="DetailsLubricator" title="Ver Lubrificador"/>
                            </RequireAuth>
                        }/>
                        <Route path="/breathers/manage" element={
                            <RequireAuth>
                                <RequireAdmin>
                                    <SuspenseWrapper name="ManageBreathers" title="Respiro"
                                                     documentTitle="Gerenciar Respiro"/>
                                </RequireAdmin>
                            </RequireAuth>
                        }/>
                        <Route path="/breathers/:id" element={
                            <RequireAuth>
                                <SuspenseWrapper name="DetailsBreather" title="Ver Respiro"/>
                            </RequireAuth>
                        }/>
                        <Route path="/level-displays/manage" element={
                            <RequireAuth>
                                <RequireAdmin>
                                    <SuspenseWrapper name="ManageLevelDisplays" title="Visores de Nível"
                                                     documentTitle="Gerenciar Visores de Nível"/>
                                </RequireAdmin>
                            </RequireAuth>
                        }/>
                        <Route path="/level-displays/:id" element={
                            <RequireAuth>
                                <SuspenseWrapper name="DetailsLevelDisplay" title="Ver Visor de Nível"/>
                            </RequireAuth>
                        }/>
                        <Route path="/lubricants" element={
                            <RequireAuth>
                                <RequirePermission permission="view_lubricant_costs">
                                    <SuspenseWrapper name="ListLubricants" title="Lubrificantes"/>
                                </RequirePermission>
                            </RequireAuth>
                        }/>
                        <Route path="/dashboard" element={
                            <RequireAuth>
                                <RequirePermission permission="view_lubricant_costs">
                                    <SuspenseWrapper name="Dashboard" title="Dashboard"/>
                                </RequirePermission>
                            </RequireAuth>
                        }/>
                        <Route path="/companies" element={
                            <RequireAuth>
                                <RequireAdmin>
                                    <SuspenseWrapper name="ListCompanies" title="Empresas"/>
                                </RequireAdmin>
                            </RequireAuth>
                        }/>
                        <Route path="/users" element={
                            <RequireAuth>
                                <RequireAdmin>
                                    <SuspenseWrapper name="ListUsers" title="Usuários"/>
                                </RequireAdmin>
                            </RequireAuth>
                        }/>
                        <Route path="/reset-password"
                               element={<SuspenseWrapper name="ResetPassword" title="Redefinir Senha"/>}/>
                        <Route path="/email/verify/:user_id/:email" element={
                            <RequireAuth>
                                <SuspenseWrapper name="VerifyEmail" title="Confirmar e-mail"/>
                            </RequireAuth>
                        }/>
                        <Route path="*" element={<Navigate to="../login"/>}/>
                    </Routes>
                </Main>
                {isMobile && <DialogInstallation open={open}/>}
                <DialogNotifications open={open}/>
            </NotificationsProvider>
            <ListAlerts/>
        </BrowserRouter>
    );
}

export default RouteList;

export {RouteList};