import {useEffect} from 'react';
import ListNotifications from './ListNotifications';
import {useNotifications} from '../hooks/useNotifications';
import {Link as RouterLink, useLocation} from 'react-router';
import {Avatar, Badge, IconButton, Menu, MenuItem, Tooltip} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NotificationsIcon from '@mui/icons-material/NotificationsTwoTone';

function Notifications() {
    const local = useLocation();
    const {
        loading,
        notifications,
        handleShowNotifications,
        handleCloseNotifications,
        showNotifications,
    } = useNotifications();

    // eslint-disable-next-line
    useEffect(() => handleCloseNotifications, []);

    if (local.pathname === '/notifications') return null;

    return (
        <>
            <Tooltip title="Notificações">
                <IconButton
                    size="large"
                    color="inherit"
                    onClick={handleShowNotifications}
                    aria-label={
                        notifications?.meta?.unread ?
                            `Veja ${notifications.meta.unread} novas notificações` :
                            'Sem novas notificações'
                    }
                >
                    <Badge
                        max={99}
                        color="error"
                        badgeContent={notifications?.meta?.unread}
                    >
                        <Avatar sx={{bgcolor: 'background.default', color: 'primary.light'}}>
                            <NotificationsIcon/>
                        </Avatar>
                    </Badge>
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-notifications"
                anchorEl={showNotifications}
                open={Boolean(showNotifications)}
                onClose={handleCloseNotifications}
                slotProps={{
                    paper: {
                        sx: {
                            width: 400,
                            maxWidth: '90%',
                        }
                    }
                }}
            >
                <ListNotifications/>
                {
                    !loading && notifications?.meta?.total > notifications?.meta?.per_page && (
                        <RouterLink
                            to="../notifications"
                            onClick={handleCloseNotifications}
                            className="no-underline text-inherit"
                            style={{borderTop: '1px solid rgba(0, 0, 0, 0.12)',}}
                        >
                            <MenuItem
                                key={-2}
                                className="flex items-center justify-center pt-1 pb-0"
                            >
                                <Tooltip title="Ver todas as notificações">
                                    <MoreHorizIcon/>
                                </Tooltip>
                            </MenuItem>
                        </RouterLink>
                    )
                }
            </Menu>
        </>
    )
}

export default Notifications;

export {Notifications};