const adjustWith = ({theme, open, authenticated}) => ({
    transition: theme.transitions.create(['width', 'margin-left'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(authenticated && open && {
        [theme.breakpoints.up('lg')]: {
            marginLeft: '280px',
            width: 'calc(100% - 280px)',
        },
    }),
    ...(authenticated && !open && {
        [theme.breakpoints.up('lg')]: {
            marginLeft: '100px',
            width: 'calc(100% - 100px)',
        },
    }),
    ...((!authenticated) && {
        [theme.breakpoints.down('lg')]: {
            width: '100%',
        },
    }),
});

export default adjustWith;

export {adjustWith};