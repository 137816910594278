import {useMediaQuery, useTheme} from '@mui/material';

const useBreakpoint = () => {
    const theme = useTheme();

    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));
    const isLg = useMediaQuery(theme.breakpoints.only('lg'));

    if (isXs) {
        return 'xs';
    }

    if (isSm) {
        return 'sm';
    }

    if (isMd) {
        return 'md';
    }

    if (isLg) {
        return 'lg';
    }

    return 'xl';
};


export default useBreakpoint;

export {useBreakpoint}