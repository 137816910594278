import {api} from '../services';
import Grid from '@mui/material/Grid2';
import {uppercaseFirst} from '../utils';
import {Controller} from 'react-hook-form';
import {useEffect, useRef, useState} from 'react';
import {monitorTypes} from '../dictonarys/monitorTypes';
import {Autocomplete, TextField, Typography} from '@mui/material';

function FindNewMonitor({type, monitor, control}) {
    const text = monitorTypes[type]?.text;
    const Icon = monitorTypes[type]?.icon;
    const timeoutSearch = useRef(null);
    const [query, setQuery] = useState('');
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleDisableSubmit = e => e.keyCode === 13 && e.preventDefault() && setQuery(search);

    const handleTimeoutSearch = () => {
        clearTimeout(timeoutSearch.current);
        timeoutSearch.current = setTimeout(() => setQuery(search), 1000);
    };

    useEffect(() => {
        if (!query) {
            let newOptions = [];

            if (monitor) {
                newOptions = [monitor];
            }

            setOptions(newOptions);
            return undefined;
        }

        setLoading(true);

        api
            .get(`/${type}s/associate`, {
                params: {
                    query,
                }
            })
            .then(response => {
                let newOptions = [];

                if (response.data) {
                    newOptions = [...response.data.data];
                }

                setOptions(newOptions);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, [monitor, query]);

    if (!text) return false;

    return (
        <Controller
            name="monitor"
            control={control}
            defaultValue={null}
            render={({field}) => (
                <Autocomplete
                    options={options}
                    loading={loading}
                    value={field.value}
                    filterOptions={x => x}
                    onInputChange={(event, value) => setSearch(value)}
                    getOptionLabel={option => option.serial}
                    noOptionsText={query ? `Nenhum ${text} encontrado` : `Digite o serial ou id do ${text}`}
                    onChange={(event, value) => {
                        setQuery('');
                        field.onChange(value);
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            fullWidth
                            margin="normal"
                            label={uppercaseFirst(text)}
                            onKeyUp={handleTimeoutSearch}
                            onKeyDown={handleDisableSubmit}
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={props.key}>
                            <Grid container alignItems="center">
                                <Grid sx={{display: 'flex', width: 44}}>
                                    <Icon/>
                                </Grid>
                                <Grid sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                                    <Grid container direction="column">
                                        <Grid>
                                            {option.serial}
                                        </Grid>
                                        <Grid>
                                            <Typography variant="caption">
                                                {uppercaseFirst(text)} #{option.id}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </li>
                    )}
                />
            )}
        />
    );
}

export default FindNewMonitor;

export {FindNewMonitor};