import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {useAuth} from '../hooks';
import BtnClose from './BtnClose';
import Grid from '@mui/material/Grid2';
import {useContext, useState} from 'react';
import {AlertContext, api} from '../services';
import ClearIcon from '@mui/icons-material/Clear';

function BtnClear({lubricator, breather, levelDisplay, name, refresh, disabled = false}) {
    const theme = useTheme();
    const {user, handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    let monitor = 'visor de nível';
    let type = 'level-display';

    const onClose = () => setShowConfirmDelete(false);

    if (lubricator) {
        monitor = 'lubrificador';
        type = 'lubricator';
    } else if (breather) {
        monitor = 'respiro';
        type = 'breather';
    }

    function clearHistory() {
        setLoading(true);

        api
            .patch(`/${type}s/${lubricator ?? breather ?? levelDisplay}/clear`)
            .then(response => {
                onClose();
                refresh();
                newAlert(response?.data?.message, 'success');
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 404) newAlert(`O ${monitor} solicitado não foi encontrado.`);
                else if (code === 403) newAlert(`Você não tem permissão para limpar o histórico desse ${monitor}.`);
                else newAlert(`Erro ao tentar limpar o histórico desse ${monitor}. Tente novamente mais tarde.`);
            })
            .finally(() => setLoading(false));
    }

    if (!user.is_admin) return null;

    return (
        <>
            <Grid>
                <Button
                    color="error"
                    loading={loading}
                    variant="outlined"
                    disabled={disabled}
                    className="mt-[16px]"
                    onClick={() => setShowConfirmDelete((true))}
                    endIcon={<ClearIcon/>}
                >
                    Limpar
                </Button>
            </Grid>
            <Dialog
                maxWidth="sm"
                open={Boolean(showConfirmDelete)}
                onClose={onClose}
                fullScreen={fullScreen}
            >
                <DialogTitle>
                    <Box>
                        Excluir o histórico do {name}?
                    </Box>
                    <BtnClose onClick={onClose}/>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Todos os dados serão apagados de forma permanente.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="justify-end">
                    <Button
                        autoFocus
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        color="error"
                        loading={loading}
                        onClick={clearHistory}
                    >
                        Excluir
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default BtnClear;

export {BtnClear};