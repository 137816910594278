import {useAuth} from '../hooks';
import BtnClose from './BtnClose';
import {uppercaseFirst} from '../utils';
import {useContext, useState} from 'react';
import {AlertContext, api} from '../services';
import monitorTypes from '../dictonarys/monitorTypes';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

function ConfirmDeleteMonitor({type, fullScreen, showConfirmDelete, onClose, setMonitors}) {
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const text = monitorTypes[type].text;

    function deleteMonitor() {
        setLoading(true);

        api
            .delete(`/${type}s/${showConfirmDelete?.id}`)
            .then(response => {
                onClose();
                newAlert(response?.data?.message, 'success');
                setMonitors(monitors => {
                    const newMonitors = {...monitors};

                    newMonitors.data = newMonitors.data.filter(monitor => monitor.id !== showConfirmDelete?.id);
                    newMonitors.meta.total--;

                    return newMonitors;
                });
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? `Erro ao tentar excluir o ${uppercaseFirst(text)} #${showConfirmDelete?.id}. Tente novamente mais tarde.`);
            })
            .finally(() => setLoading(false));
    }

    return (
        <Dialog
            maxWidth="sm"
            open={Boolean(showConfirmDelete?.open)}
            onClose={onClose}
            fullScreen={fullScreen}
        >
            <DialogTitle>
                <Box>
                    Excluir o {uppercaseFirst(text)} #{showConfirmDelete?.id}?
                </Box>
                <BtnClose onClick={onClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    A exclusão desse {text} removerá todos os status associados a ele.
                </DialogContentText>
                <div className="text-center font-bold mt-3">
                    ESTA AÇÃO NÃO PODERÁ SER DESFEITA!
                </div>
            </DialogContent>
            <DialogActions className="justify-end">
                <Button
                    autoFocus
                    variant="outlined"
                    onClick={onClose}
                >
                    Cancelar
                </Button>
                <Button
                    color="error"
                    loading={loading}
                    onClick={deleteMonitor}
                >
                    Deletar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDeleteMonitor;

export {ConfirmDeleteMonitor};