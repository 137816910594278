import {BtnClose} from './';
import {useAuth} from '../hooks';
import Grid from '@mui/material/Grid2';
import {useForm} from 'react-hook-form';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,} from '@mui/material';

function CreateOrEditRole({role, onClose, fullScreen, loadRoles}) {
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);

    const {
        reset,
        register,
        setError,
        handleSubmit,
        formState: {errors},
    } = useForm();

    function onSubmit(data) {
        let apiMethod = api.post;
        let apiEndpoint = '/roles';

        if (role?.data?.id) {
            apiMethod = api.put;
            apiEndpoint += '/' + role.data.id;
        }

        setLoading(true);

        apiMethod(apiEndpoint, {
            name: data.name,
            description: data.description,
        })
            .then(response => {
                onClose();
                newAlert(response?.data?.message, 'success');
                loadRoles();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) {
                    Object.entries(error.response?.data?.errors)
                        .forEach(([key, message]) => {
                            setError(key, {
                                type: 422,
                                message: message[0],
                            });
                        });
                } else {
                    const action = role?.data?.id ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao tentar ${action} a função. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (!role?.open) return;

        reset({
            name: role?.data?.name ?? '',
            description: role?.data?.description ?? '',
        });
        // eslint-disable-next-line
    }, [role]);

    return (
        <Dialog
            maxWidth="md"
            open={Boolean(role?.open)}
            onClose={onClose}
            fullScreen={fullScreen}
            slotProps={{
                paper: {
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit),
                },
            }}
        >
            <DialogTitle>
                <Box>
                    {role?.data?.id ? 'Editar' : 'Nova'} função
                </Box>
                <BtnClose onClick={onClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container columnSpacing={7}>
                    <Grid size={{xs: 12, sm: 6}}>
                        <TextField
                            autoFocus
                            fullWidth
                            type="text"
                            label="Nome"
                            margin="normal"
                            error={Boolean(errors?.name)}
                            helperText={errors?.name?.message}
                            {...register('name', {
                                required: 'O nome da função é obrigatório.',
                            })}
                        />
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                        <TextField
                            fullWidth
                            type="text"
                            margin="normal"
                            label="Descrição"
                            {...register('description')}
                            error={Boolean(errors?.description)}
                            helperText={errors?.description?.message}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    type="submit"
                    className="px-12"
                    loading={loading}
                    disabled={Object.keys(errors).length > 0}
                >
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CreateOrEditRole;

export {CreateOrEditRole};