import {
    Alert,
    Box,
    Button,
    Checkbox,
    IconButton,
    ListItem,
    ListItemButton,
    Menu,
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {
    ConfirmDeleteMonitor,
    CreateOrEditMonitor,
    FilterRecords,
    StyledTableCell,
    StyledTableRow,
    TablePagination,
    TableSortLabel,
} from '../components';
import env from 'react-dotenv';
import {useAuth} from '../hooks';
import {Link} from 'react-router';
import {AlertContext, api} from '../services';
import {toLocaleString} from '../utils/toLocaleString';
import {useContext, useEffect, useRef, useState} from 'react';
import {lubricatorStatuses} from '../dictonarys/lubricatorStatuses';
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturingOutlined';

function TableLubricators({manage = false}) {
    const theme = useTheme();
    const {newAlert} = useContext(AlertContext);
    const {workspace, handleLogout, user} = useAuth();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [lubricators, setLubricators] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [filters, setFilters] = useState(['up', 'error']);
    const [showConfirmDelete, setShowConfirmDelete] = useState({open: false});
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [lubricator, setLubricator] = useState({open: false});
    const [query, setQuery] = useState('');
    const interval = parseInt(localStorage.getItem('refresh') ?? env.REFRESH_DEFAULT_INTERVAL) * 60 * 1000;
    let timeout = useRef(null);

    const handleMenu = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const confirmDelete = id => setShowConfirmDelete({open: true, id});

    const Icons = {
        component: RadioButtonCheckedIcon,
        subset: SettingsIcon,
        machine: PrecisionManufacturingIcon
    };

    function handleChange(type) {
        if (filters.length === 1 && filters[0] === type) return;

        if (filters.includes(type)) setFilters(filters.filter(filter => filter !== type));
        else setFilters([...filters, type]);
    }

    function loadLubricators() {
        clearTimeout(timeout.current);
        setLoading(true);

        let url = '/lubricators';
        let params = {
            page: page + 1,
            per_page: perPage,
            order,
            order_by: orderBy,
            filters,
            query,
        }

        if (manage) url += '/all';
        else params['workspace'] = workspace;

        api
            .get(url, {params})
            .then(response => {
                setLubricators(response.data);
                timeout.current = setTimeout(loadLubricators, interval);
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    setLubricators({});
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar lubrificadores. Tente novamente mais tarde.');
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        loadLubricators();

        return () => clearTimeout(timeout.current);
        // eslint-disable-next-line
    }, [page, perPage, workspace, order, orderBy, filters, query]);

    return (
        <>
            <Box className="w-full">
                <FilterRecords
                    setQuery={setQuery}
                    setPage={setPage}
                    loading={loading}
                    variant="contained"
                    onFilter={handleMenu}
                    refresh={loadLubricators}
                    placeholder="Pesquisar por id, serial ou local de instalação ..."
                />
                <Menu
                    id="filter-menu"
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                >
                    {
                        Object.values(lubricatorStatuses).map(({type, text, color}) => (
                            <ListItem disablePadding key={type}>
                                <ListItemButton
                                    dense
                                    id={`filter-lubricators-${type}`}
                                    className="flex items-center justify-start"
                                    onClick={() => handleChange(type)}
                                >
                                    <Checkbox
                                        edge="start"
                                        checked={filters.indexOf(type) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        disabled={filters.length === 1 && filters[0] === type}
                                        inputProps={{
                                            'aria-labelledby': `filter-lubricators-${type}`,
                                        }}
                                    />
                                    <CircleIcon sx={{color}} className="ml-[5px] mr-4"/>
                                    {text}
                                </ListItemButton>
                            </ListItem>
                        ))
                    }
                </Menu>
                <TableContainer component={Paper}>
                    <Table
                        stickyHeader
                        size="small"
                        aria-label="Lubrificadores"
                        sx={{minWidth: user?.is_admin ? 1100 : 1050}}
                    >
                        <TableHead>
                            <TableRow>
                                {
                                    [
                                        {label: 'id', text: 'ID', short: true},
                                        {label: 'serial', text: 'Serial', short: true},
                                        {label: 'current_capacity', text: 'Capacidade', short: true},
                                        {label: 'lubricatable_type', text: 'Instalado na(o)', short: true},
                                        {label: 'alert_type', text: 'Status', short: false},
                                        {label: 'actions', text: 'Ações', short: false},
                                    ].map(({label, text, short}) => (
                                        <TableSortLabel
                                            key={label}
                                            label={label}
                                            text={text}
                                            short={short}
                                            order={order}
                                            setOrder={setOrder}
                                            orderBy={orderBy}
                                            setOrderBy={setOrderBy}
                                        />
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading ? (
                                        new Array(perPage).fill(1).map((value, index) => (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell>
                                                    <Skeleton/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={250}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={60}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton width={200}/>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Skeleton/>
                                                </StyledTableCell>
                                                <StyledTableCell className="flex justify-center items-center">
                                                    <Skeleton className="mx-2"/>
                                                    {
                                                        user?.is_admin && (
                                                            <>
                                                                <Skeleton className="mx-2"/>
                                                                <Skeleton className="mx-2"/>
                                                            </>
                                                        )
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))
                                    ) :
                                    (
                                        (lubricators && lubricators?.data?.map(lubricator => {
                                            const Icon = Icons[lubricator?.lubricatable_type] ?? SettingsIcon;

                                            const color = lubricatorStatuses[lubricator?.status].color;
                                            const status = lubricatorStatuses[lubricator?.status].text;

                                            return (
                                                <StyledTableRow key={lubricator?.id}>
                                                    <StyledTableCell>
                                                        {lubricator?.id}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        GETLUB-LBR-{lubricator?.serial}
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        {toLocaleString(lubricator?.total_capacity)} g
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Box className="flex items-center justify-center">
                                                            {
                                                                lubricator?.lubricatable_type ? (
                                                                    <>
                                                                        <Icon className="mr-1"/>
                                                                        {lubricator?.lubricatable?.name}
                                                                    </>
                                                                ) : 'Não instalado'
                                                            }
                                                        </Box>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Tooltip title={status}>
                                                            <CircleIcon sx={{color}}/>
                                                        </Tooltip>
                                                    </StyledTableCell>
                                                    <StyledTableCell>
                                                        <Tooltip title="Detalhar">
                                                            <Link to={`/lubricators/${lubricator?.id}`}>
                                                                <IconButton
                                                                    color="primary"
                                                                    aria-label="Detalhar"
                                                                >
                                                                    <VisibilityIcon/>
                                                                </IconButton>
                                                            </Link>
                                                        </Tooltip>
                                                        {
                                                            user?.is_admin && (
                                                                <>
                                                                    <Tooltip title="Editar">
                                                                        <IconButton
                                                                            color="warning"
                                                                            aria-label="Editar"
                                                                            onClick={() => {
                                                                                setLubricator({
                                                                                    open: true,
                                                                                    id: lubricator?.id,
                                                                                    serial: lubricator?.serial,
                                                                                    duration: lubricator?.lubricatable_type ? lubricator?.duration : null,
                                                                                    total_capacity: lubricator?.total_capacity,
                                                                                    current_capacity: lubricator?.current_capacity,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <EditIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Apagar">
                                                                        <IconButton
                                                                            color="error"
                                                                            aria-label="Apagar"
                                                                            onClick={() => confirmDelete(lubricator?.id)}
                                                                        >
                                                                            <DeleteIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>
                                                            )
                                                        }
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        }))
                                    )
                            }
                            {
                                !loading && !lubricators?.data?.length && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            <Alert severity="warning">
                                                Nenhum lubrificador encontrado para os filtros informados.
                                            </Alert>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={6}
                                    rowsPerPage={perPage}
                                    setPerPage={setPerPage}
                                    page={page}
                                    setPage={setPage}
                                    count={lubricators?.meta?.total ?? 0}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                {
                    manage && (
                        <Box className="text-end w-full mt-8">
                            <Button onClick={() => setLubricator({open: true})}>
                                Novo Lubrificador
                            </Button>
                        </Box>
                    )
                }
            </Box>
            {
                user?.is_admin && (
                    <>
                        <ConfirmDeleteMonitor
                            type="lubricator"
                            showConfirmDelete={showConfirmDelete}
                            onClose={() => setShowConfirmDelete({...showConfirmDelete, open: false})}
                            fullScreen={fullScreen}
                            setMonitors={setLubricators}
                        />
                        <CreateOrEditMonitor
                            type="lubricator"
                            monitor={lubricator}
                            onClose={() => setLubricator({...lubricator, open: false})}
                            fullScreen={fullScreen}
                            loadMonitors={loadLubricators}
                        />
                    </>
                )
            }
        </>
    );
}

export default TableLubricators;

export {TableLubricators};