import {useTitle} from '../hooks';
import {CircularProgress} from '@mui/material';
import {lazy, Suspense, useEffect} from 'react';

function SuspenseWrapper({name, title, documentTitle}) {
    const {setTitle} = useTitle();
    const Component = lazy(() => import(`../routes/${name}`));

    // eslint-disable-next-line
    useEffect(() => documentTitle ? setTitle(title, documentTitle) : setTitle(title), [title, documentTitle]);

    return (
        <Suspense fallback={<CircularProgress className="m-auto"/>} key={name}>
            <Component/>
        </Suspense>
    );
}

export default SuspenseWrapper;

export {SuspenseWrapper};