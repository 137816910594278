import {useState} from 'react';
import {useAuth} from '../hooks/useAuth';
import useBreakpoint from '../hooks/useBreakpoint';
import {uppercaseFirst} from '../utils/uppercaseFirst';
import {monitorTypes} from '../dictonarys/monitorTypes';
import {Breadcrumbs as MUIBreadcrumbs, Chip, Tooltip, Typography} from '@mui/material';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturingOutlined';

function Breadcrumbs({location, type, options, handleMenu, ...props}) {
    const {setWorkspace} = useAuth();
    const breakpoint = useBreakpoint();
    const maxItems = {
        xs: 1,
        sm: 1.5,
        md: 2,
        lg: 3,
        xl: 4,
    };
    const maxItem = maxItems[breakpoint];
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => setOpen(false);

    const handleTooltipOpen = () => setOpen(true);

    if (!location) {
        return (
            <Typography
                variant="caption"
                component="span"
                className="font-bold"
            >
                {uppercaseFirst(monitorTypes[type].text)} não instalado
            </Typography>
        );
    }

    let total;
    let chips;

    if (handleMenu) {
        const icon = {
            null: <HomeIcon sx={{mr: 0.5}} fontSize="small"/>,
            true: <CorporateFareIcon sx={{mr: 0.5}} fontSize="small"/>,
            false: <FolderIcon sx={{mr: 0.5}} fontSize="small"/>,
        };

        total = location.length;

        chips = location.map((folder, i) => (
            <Chip
                clickable
                key={i}
                icon={icon[folder.is_root]}
                label={folder.name}
                onClick={options > 0 && location.length - 1 === i ? handleMenu : () => setWorkspace(folder.id)}
            />
        ));
    } else {
        total = location.folders.length + 1 + (location?.subset ? 1 : 0) + (location?.component ? 1 : 0);

        chips = [
            ...location.folders.map((folder, i) => (
                <Chip
                    key={i}
                    label={folder}
                    icon={i === 0 ?
                        <CorporateFareIcon fontSize="small"/> :
                        <FolderIcon fontSize="small"/>
                    }
                />
            ))
        ];

        if (location?.machine) {
            chips.push(
                <Chip
                    key="machine"
                    icon={<PrecisionManufacturingIcon fontSize="small"/>}
                    label={location.machine}
                />
            );
        }

        if (location?.subset) {
            chips.push(
                <Chip
                    key="subset"
                    icon={<SettingsIcon fontSize="small"/>}
                    label={location.subset}
                />
            );
        }

        if (location?.component) {
            chips.push(
                <Chip
                    key="component"
                    icon={<RadioButtonCheckedIcon fontSize="small"/>}
                    label={location.component}
                />
            );
        }
    }

    if (total > maxItem) {
        const more = <Chip icon={<MoreHorizIcon fontSize="small"/>}/>;

        function getTooltip(tooltip) {
            return (
                <Tooltip
                    key="more"
                    open={open}
                    title={tooltip}
                    onOpen={handleTooltipOpen}
                    onClick={handleTooltipOpen}
                    onClose={handleTooltipClose}
                >
                    {more}
                </Tooltip>
            );
        }

        if (maxItem === 1) {
            chips = chips.slice(-1);
        } else if (maxItem === 1.5) {
            const tooltip = chips.slice(0, -1).map(chip => chip.props.label).join(' > ');

            chips = [getTooltip(tooltip), chips[chips.length - 1]];
        } else if (maxItem === 2) {
            const tooltip = chips.slice(1, -1).map(chip => chip.props.label).join(' > ');

            chips = [chips[0], getTooltip(tooltip), chips[chips.length - 1]];
        } else if (maxItem === 3) {
            const tooltip = chips.slice(1, -2).map(chip => chip.props.label).join(' > ');

            chips = [chips[0], getTooltip(tooltip), ...chips.slice(-2)];
        } else if (maxItem === 4) {
            const tooltip = chips.slice(1, -3).map(chip => chip.props.label).join(' > ');

            chips = [chips[0], getTooltip(tooltip), ...chips.slice(-3)];
        } else {
            const tooltip = chips.slice(1, -4).map(chip => chip.props.label).join(' > ');

            chips = [chips[0], getTooltip(tooltip), ...chips.slice(-4)];
        }
    }

    return (
        <MUIBreadcrumbs {...props} className={`xs:mx-auto md:mx-0 ${props?.className}`}>
            {chips}
        </MUIBreadcrumbs>
    );
}

export default Breadcrumbs;

export {Breadcrumbs};