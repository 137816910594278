import {
    Button,
    CircularProgress,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography
} from '@mui/material';
import {useAuth} from '../hooks';
import Grid from '@mui/material/Grid2';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useState} from 'react';
import TvIcon from '@mui/icons-material/Tv';
import TabletIcon from '@mui/icons-material/Tablet';
import ComputerIcon from '@mui/icons-material/Computer';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DeviceUnknownIcon from '@mui/icons-material/DeviceUnknown';

function getIcon(name) {
    if (name.indexOf('Tablet') !== -1) return TabletIcon;
    if (name.indexOf('Smartphone') !== -1) return SmartphoneIcon;
    if (name.indexOf('Smart TV') !== -1) return TvIcon;
    if (name.indexOf('Desktop') !== -1) return ComputerIcon;
    return DeviceUnknownIcon;
}

function ActiveSessions() {
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loadingEnd, setLoadingEnd] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sessions, setSessions] = useState([]);

    const generateItems = (sessions, action = true) => (
        <Grid
            container
            className="mt-3"
            size={12}
            spacing={2}
            justifyContent={action ? 'space-between' : 'center'}
        >
            {
                sessions.map(session => {
                    const Icon = getIcon(session.name);

                    return (
                        <Grid size={{xs: 12, lg: 5}} key={session?.id}>
                            <ListItem
                                className="py-4"
                                secondaryAction={
                                    !action ?
                                        <Typography
                                            variant="caption"
                                            component="span"
                                        >
                                            Atual
                                        </Typography>
                                        :
                                        loadingEnd ?
                                            <CircularProgress size={20}/>
                                            :
                                            <Tooltip title="Encerrar">
                                                <IconButton
                                                    color="error"
                                                    edge="end"
                                                    onClick={() => endSession(session?.id)}
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Tooltip>
                                }
                            >
                                <ListItemIcon>
                                    <Icon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={session?.name}
                                    secondary={`Último acesso: ${session.last_used_at}`}
                                />
                            </ListItem>
                        </Grid>
                    );
                })
            }
        </Grid>
    );

    function endSession(id) {
        setLoadingEnd(true);

        api
            .delete('/sessions/' + id)
            .then(response => {
                newAlert(response?.data?.message, 'success');

                setSessions(sessions => {
                    const newSessions = {...sessions};

                    newSessions.data.others = newSessions.data.others.filter(session => session.id !== id);

                    return newSessions;
                });
            })
            .catch(error => {
                if (error.response?.status === 401) handleLogout();
                else newAlert('Não foi possível encerrar essa sessão. Tente novamente mais tarde.')
            })
            .finally(() => setLoadingEnd(false))
    }

    function endAllSessions() {
        setLoadingEnd(true);

        api
            .delete('/sessions')
            .then(response => {

                newAlert(response?.data?.message, 'success');

                setSessions(sessions => {
                    const newSessions = {...sessions};

                    newSessions.data.others = [];

                    return newSessions;
                });
            })
            .catch(error => {
                if (error.response?.status === 401) handleLogout();
                else newAlert('Não foi possível encerrar as sessões. Tente novamente mais tarde.')
            })
            .finally(() => setLoadingEnd(false))
    }

    useEffect(() => {
        api.get('/sessions')
            .then(response => setSessions(response?.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert('Erro ao tentar carregar as sessões ativas. Tente novamente mais tarde.');

                setSessions({});
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                loading ? (
                    <Grid textAlign="center">
                        <CircularProgress className="mt-5 mb-3"/>
                    </Grid>
                ) : (
                    <>
                        {generateItems([sessions.data.current], false)}
                        {generateItems(sessions.data.others)}
                    </>
                )
            }
            <Grid textAlign="right" className="p-3">
                <Button
                    color="error"
                    variant="outlined"
                    className="my-3 mx-auto"
                    disabled={loadingEnd || sessions?.data?.others?.length === 0}
                    onClick={endAllSessions}
                >
                    Encerrar sessões
                </Button>
            </Grid>
        </>
    );

}

export default ActiveSessions;

export {ActiveSessions};
