function exportReportErrorXLS(data) {
    const errors = {
        error: 'Falha',
        offline: 'Sem Conexão',
        grease: 'Falta de Lubrificante',
        battery: 'Bateria Fraca',
        saturated: 'Saturado',
        low: 'Nível Baixo',
    };
    const monitors = {
        lubricators: 'Lubrificadores',
        breathers: 'Respiros',
        'level-displays': 'Visores de nível',
    };

    const detailsAlert = data.alerts.map(alert => [
        alert.id,
        errors[alert.type],
        monitors[alert.alertable_type + 's'],
        alert.resolved_at ? 'Resolvido' : 'Ativo',
        alert.started_at,
        alert.resolved_at ?? '-',
    ]);

    if (detailsAlert.length === 0) {
        detailsAlert.push([
            '-',
            '-',
            '-',
            '-',
            '-',
            '-',
        ])
    }

    import('exceljs').then(({Workbook}) => {
        const workbook = new Workbook();
        const resumeSheet = workbook.addWorksheet('Resumo dos Alertas');
        const detailsSheet = workbook.addWorksheet('Detalhes dos Alertas');
        const state = data.types[0];
        const allTypes = data.types.length === 2;

        const detailsData = [
            ['ID', 'Tipo', 'Equipamento', 'Status', 'Iniciado em', 'Resolvido em'],
            ...detailsAlert
        ];

        workbook.creator = 'GetLub';
        workbook.created = new Date();
        workbook.lastModifiedBy = 'GetLub';
        workbook.modified = new Date();

        detailsSheet.addTable({
            name: 'DetailsTable',
            ref: 'A1',
            headerRow: true,
            style: {
                theme: 'TableStyleMedium9',
                showRowStripes: true,
            },
            columns: detailsData[0].map(header => ({name: header})),
            rows: detailsData.slice(1)
        });

        function adjustColumnWidths(sheet, data) {
            const columnWidths = data[0].map((col, i) => Math.max(
                ...data.map(row => (row[i] ? row[i].toString().length : 0))
            ));
            sheet.columns.forEach((col, i) => {
                col.width = columnWidths[i] + 5;
                col.alignment = {horizontal: 'center', vertical: 'middle'};
            });
        }

        adjustColumnWidths(detailsSheet, detailsData);

        const resumeData = [];
        let headersResume = ['Tipo de Alerta', 'Equipamento', 'Ativos', 'Resolvidos', 'Total'];

        if (!allTypes) {
            if (state === 'active') {
                headersResume = ['Tipo de Alerta', 'Equipamento', 'Ativos'];
            } else {
                headersResume = ['Tipo de Alerta', 'Equipamento', 'Resolvidos'];
            }
        }

        let line = 2;
        Object.entries(data.counts).forEach(([type, error]) => {
            Object.entries(error).forEach(([subitem]) => {
                if (allTypes) {
                    resumeData.push([
                        errors[type],
                        monitors[subitem],
                        {formula: `COUNTIFS(DetailsTable[Tipo], A${line}, DetailsTable[Equipamento], B${line}, DetailsTable[Status], "Ativo")`},
                        {formula: `COUNTIFS(DetailsTable[Tipo], A${line}, DetailsTable[Equipamento], B${line}, DetailsTable[Status], "Resolvido")`},
                        {formula: `C${line}+D${line}`},
                    ]);
                } else if (state === 'active') {
                    resumeData.push([
                        errors[type],
                        monitors[subitem],
                        {formula: `COUNTIFS(DetailsTable[Tipo], A${line}, DetailsTable[Equipamento], B${line}, DetailsTable[Status], "Ativo")`},
                    ]);
                } else {
                    resumeData.push([
                        errors[type],
                        monitors[subitem],
                        {formula: `COUNTIFS(DetailsTable[Tipo], A${line}, DetailsTable[Equipamento], B${line}, DetailsTable[Status], "Resolvido")`},
                    ]);
                }

                line++;
            });
        });

        if (allTypes) {
            resumeData.push([
                'Total',
                '-',
                {formula: `SUBTOTAL(9, C2:C${line - 1})`},
                {formula: `SUBTOTAL(9, D2:D${line - 1})`},
                {formula: `SUBTOTAL(9, E2:E${line - 1})`},
            ]);
        } else {
            resumeData.push([
                'Total',
                '-',
                {formula: `SUBTOTAL(9, C2:C${line - 1})`},
            ]);
        }

        resumeSheet.addTable({
            name: 'ResumeTable',
            ref: 'A1',
            headerRow: true,
            style: {
                theme: 'TableStyleMedium9',
                showRowStripes: true
            },
            columns: headersResume.map(header => ({name: header})),
            rows: resumeData,
        });

        adjustColumnWidths(resumeSheet, resumeData);

        resumeSheet.getRow(line).font = {bold: true};

        resumeSheet.getRow(1).eachCell(cell => {
            cell.font = {
                bold: true,
                color: {
                    argb: 'FFFFFFFF'
                },
                size: 12
            };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'FF0874BB',
                },
            };
        });

        resumeSheet.eachRow({includeEmpty: true}, row => {
            row.height = 20;
        });

        detailsSheet.getRow(1).eachCell(cell => {
            cell.font = {
                bold: true,
                color: {
                    argb: 'FFFFFFFF'
                },
                size: 12
            };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                    argb: 'FF0874BB',
                },
            };
        });

        detailsSheet.eachRow({includeEmpty: true}, row => {
            row.height = 20;
        });

        // Salvando o arquivo Excel
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], {type: 'application/octet-stream'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');

            a.href = url;
            a.download = `Relatório de Alertas – ${data.name} – ${data.start} - ${data.end}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    });
}

export default exportReportErrorXLS;

export {exportReportErrorXLS};