import {styled} from '@mui/system';
import {useLocation} from 'react-router';
import {useAuth} from '../hooks/useAuth';

const Main = styled('main', {shouldForwardProp: prop => prop !== 'open'})(({theme, open}) => {
    const local = useLocation()
    const {authenticated} = useAuth();

    return {
        flexGrow: 1,
        minHeight: local.pathname === '/login' ? '100vh' : 'calc(100vh - 64px)',
        padding: theme.spacing(3),
        transition: theme.transitions.create(['width', 'margin-left'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(authenticated && open && {
            [theme.breakpoints.up('lg')]: {
                marginLeft: '280px',
                width: 'calc(100% - 280px)',
            },
        }),
        ...(authenticated && !open && {
            [theme.breakpoints.up('lg')]: {
                marginLeft: '100px',
                width: 'calc(100% - 100px)',
            },
        }),
        ...((!authenticated) && {
            [theme.breakpoints.down('lg')]: {
                width: '100%',
            },
        }),
    };
});

export default Main;

export {Main};