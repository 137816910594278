import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Typography
} from '@mui/material';
import {BtnClose} from './index';
import {useAuth} from '../hooks';
import Grid from '@mui/material/Grid2';
import {states} from '../dictonarys/states';
import {AlertContext, api} from '../services';
import {Controller, useForm} from 'react-hook-form';
import {useContext, useEffect, useState} from 'react';
import {cepMask, cnpjMask, uppercaseFirst} from '../utils';

function CreateOrEditFolder({folder, setFolder, fullScreen, loadFolders, type}) {
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const {can, handleLogout, setLoadingBackdrop} = useAuth();
    const actions = {
        create: 'Adicionar',
        edit: 'Salvar',
        show: 'Editar',
        duplicate: 'Duplicar',
    };
    const {
        reset,
        watch,
        control,
        register,
        setError,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const id = watch('id');

    const handleClose = () => setFolder({...folder, open: false});

    function handleCnpj(event) {
        const input = event.target;

        input.value = cnpjMask(input.value);
    }

    function handleCep(event) {
        const input = event.target;

        input.value = cepMask(input.value);
    }

    function onSubmit(data) {
        if (folder?.action === 'show') {
            setFolder({...folder, action: 'edit'});
            return;
        }

        const cepNumeric = data.cep.replace(/[^0-9]/g, '');
        const cnpjNumeric = data.cnpj.replace(/[^0-9]/g, '');

        let apiMethod = api.post;
        let apiEndpoint = '/folders';
        let form = {
            name: data.name,
            cnpj: cnpjNumeric,
            company_name: data.companyName,
            unit: data.unit,
            street: data.street,
            number: data.number,
            complement: data.complement,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state?.code,
            cep: cepNumeric,
        };

        if (type === 'empresa') {
            apiEndpoint += '/root';
        } else if (folder?.action === 'create') {
            form.parent_id = folder?.parent;
        }

        if (folder?.id) {
            apiEndpoint += '/' + folder.id;

            if (folder?.action !== 'duplicate') {
                apiMethod = api.put;
            } else {
                apiEndpoint += '/duplicate';
            }
        }

        setLoading(true);

        apiMethod(apiEndpoint, form)
            .then(response => {
                const expand = folder?.parent ? `folder-${folder?.parent}` : '';

                handleClose();
                loadFolders(expand);
                newAlert(response?.data?.message, 'success');
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) {
                    Object.entries(error.response?.data?.errors)
                        .forEach(([key, message]) => {
                            setError(key, {
                                type: 422,
                                message: message[0],
                            });
                        });
                } else {
                    const action = folder?.id ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao tentar ${action} a ${type}. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (!folder.open) return;

        if (folder?.id !== id) {
            reset();
        }

        if (!folder?.id || folder?.action === 'create' || folder?.id === id) return;

        setLoadingBackdrop(true);

        api.get('/folders/' + folder?.id)
            .then(response => {
                response = response.data?.data;

                reset({
                    id: response?.id,
                    name: response?.name ?? '',
                    cnpj: cnpjMask(response?.cnpj ?? ''),
                    company_name: response?.company_name ?? '',
                    unit: response?.unit ?? '',
                    street: response?.street ?? '',
                    number: response?.number ?? '',
                    complement: response?.complement ?? '',
                    neighborhood: response?.neighborhood ?? '',
                    city: response?.city ?? '',
                    state: response?.state ?? '',
                    cep: cepMask(response?.cep ?? ''),
                });
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    newAlert(error.response?.data?.message ?? `Erro ao tentar carregar a ${type}. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoadingBackdrop(false));

        // eslint-disable-next-line
    }, [folder]);

    return (
        <Dialog
            maxWidth="md"
            open={Boolean(folder?.open)}
            onClose={handleClose}
            fullScreen={fullScreen}
            slotProps={{
                paper: {
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit),
                },
            }}
        >
            <DialogTitle>
                <Box>
                    Dados da {uppercaseFirst(type)}
                </Box>
                <BtnClose onClick={handleClose}/>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3} rowSpacing={0}>
                    <Grid container size={{xs: 12, md: 6}} rowSpacing={0}>
                        <TextField
                            fullWidth
                            type="text"
                            label="Nome"
                            margin="normal"
                            error={Boolean(errors?.name)}
                            helperText={errors?.name?.message}
                            autoFocus={folder?.action !== 'show'}
                            {...register('name', {
                                required: 'O nome é obrigatório.',
                                minLength: {
                                    value: 3,
                                    message: 'O nome precisa ter pelo menos 3 caracteres.',
                                },
                            })}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            fullWidth
                            type="text"
                            label="CNPJ"
                            margin="normal"
                            onKeyUp={handleCnpj}
                            error={Boolean(errors?.cnpj)}
                            helperText={errors?.cnpj?.message}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                            {...register('cnpj', {
                                maxLength: {
                                    value: 18,
                                    message: 'O cnpj precisa ter 14 dígitos.',
                                },
                                minLength: {
                                    value: 18,
                                    message: 'O cnpj precisa ter 14 dígitos.',
                                },
                            })}
                        />
                    </Grid>
                </Grid>
                <TextField
                    fullWidth
                    type="text"
                    margin="normal"
                    label="Razão Social"
                    {...register('company_name')}
                    error={Boolean(errors?.company_name)}
                    helperText={errors?.company_name?.message}
                    slotProps={{
                        input: {
                            readOnly: folder?.action === 'show',
                        },
                    }}
                />
                <TextField
                    fullWidth
                    type="text"
                    label="Unidade"
                    margin="normal"
                    {...register('unit')}
                    error={Boolean(errors?.unit)}
                    helperText={errors?.unit?.message}
                    slotProps={{
                        input: {
                            readOnly: folder?.action === 'show',
                        },
                    }}
                />
                <Divider className="my-3" textAlign="left">
                    <Typography className="font-medium" variant="body2">
                        Endereço
                    </Typography>
                </Divider>
                <Grid container spacing={3} rowSpacing={0}>
                    <Grid size={{xs: 12, md: 10}}>
                        <TextField
                            fullWidth
                            type="text"
                            label="Rua"
                            margin="normal"
                            {...register('street')}
                            error={Boolean(errors?.street)}
                            helperText={errors?.street?.message}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 2}}>
                        <TextField
                            fullWidth
                            type="text"
                            label="Número"
                            margin="normal"
                            {...register('number')}
                            error={Boolean(errors?.number)}
                            helperText={errors?.number?.message}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            fullWidth
                            type="text"
                            label="Bairro"
                            margin="normal"
                            {...register('neighborhood')}
                            error={Boolean(errors?.neighborhood)}
                            helperText={errors?.neighborhood?.message}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            fullWidth
                            type="text"
                            margin="normal"
                            label="Complemento"
                            {...register('complement')}
                            error={Boolean(errors?.complement)}
                            helperText={errors?.complement?.message}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={12}>
                        <TextField
                            fullWidth
                            type="text"
                            label="Cidade"
                            margin="normal"
                            {...register('city')}
                            error={Boolean(errors?.city)}
                            helperText={errors?.city?.message}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        {
                            folder?.action === 'show' ? (
                                <Controller
                                    name="state"
                                    defaultValue=""
                                    control={control}
                                    render={({field}) => (
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Estado"
                                            margin="normal"
                                            value={field.value ? field.value.label : ''}
                                            slotProps={{
                                                input: {
                                                    readOnly: true,
                                                },
                                            }}
                                        />
                                    )}
                                />
                            ) : (
                                <Controller
                                    name="state"
                                    control={control}
                                    defaultValue={null}
                                    render={({field}) => (
                                        <Autocomplete
                                            fullWidth
                                            options={states}
                                            value={field.value}
                                            noOptionsText="Digite um estado do Brasil"
                                            onChange={(event, value) => field.onChange(value)}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    name="state"
                                                    label="Estado"
                                                    margin="normal"
                                                    error={Boolean(errors?.state)}
                                                    helperText={errors?.state?.message}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            )
                        }
                    </Grid>
                    <Grid size={{xs: 12, md: 6}}>
                        <TextField
                            fullWidth
                            label="CEP"
                            type="text"
                            margin="normal"
                            onKeyUp={handleCep}
                            error={Boolean(errors?.cep)}
                            helperText={errors?.cep?.message}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                            {...register('cep', {
                                maxLength: {
                                    value: 9,
                                    message: 'O cep precisa ter 8 dígitos.',
                                },
                                minLength: {
                                    value: 9,
                                    message: 'O cep precisa ter 8 dígitos.',
                                },
                            })}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            {
                (folder?.action !== 'show' || can(folder?.root === folder?.id ? 'update_root_folder' : 'update_subfolders_assets', folder?.root)) && (
                    <DialogActions>
                        <Button
                            type="submit"
                            className="px-12"
                            loading={loading}
                            disabled={Object.keys(errors).length > 0}
                        >
                            {actions[folder?.action]}
                        </Button>
                    </DialogActions>
                )
            }
        </Dialog>
    );
}

export default CreateOrEditFolder;

export {CreateOrEditFolder};