import api from '../services/api';
import {AlertContext} from '../services';
import {useContext, useEffect, useState} from 'react';

function useAuthProvider() {
    const {newAlert} = useContext(AlertContext);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(null);
    const [workspaces, setWorkspaces] = useState([]);
    const [treeWorkspaces, setTreeWorkspaces] = useState([]);
    const [authenticated, setAuthenticated] = useState(false);
    const [selectWorkspace, setSelectWorkspace] = useState(false);
    const [loadingBackdrop, setLoadingBackdrop] = useState(false);
    const [workspace, setWorkspace] = useState(parseInt(localStorage.getItem('workspace') ?? 0));

    function getWorkspace(user) {
        const list = user?.workspaces ?? {};
        const tree = user?.tree ?? [];

        delete user?.workspaces;
        delete user?.tree;

        return {list, tree};
    }

    function setWorkspacePersistent(value) {
        localStorage.setItem('workspace', value);
        setWorkspace(value);
    }

    useEffect(() => {
        const authenticated = localStorage.getItem('authenticated');

        if (authenticated) {
            api.get('/user')
                .then(response => {
                    let user = response?.data?.data;
                    const {tree, list} = getWorkspace(user);

                    if (!(workspace in Object.keys(list))) setWorkspace(0);

                    setWorkspaces(list);
                    setTreeWorkspaces(tree);

                    setUser(user)
                    setAuthenticated(true);

                    setLoading(false);
                })
                .catch(error => {
                    const code = error.response?.status;

                    if (code === 401) {
                        handleLogout();
                        setLoading(false);
                    } else newAlert('Erro no servidor, tente novamente mais tarde.');
                })
        } else setLoading(false);
        // eslint-disable-next-line
    }, []);

    function handleLogin(email, password, remember, setError) {
        return new Promise((resolve) => {
            api
                .post('/login', {
                    email,
                    password,
                    remember,
                })
                .then(response => {
                    let {user} = response.data;
                    const {tree, list} = getWorkspace(user);

                    localStorage.setItem('authenticated', 'true');
                    if (!(workspace in Object.keys(list))) setWorkspace(0);

                    setWorkspaces(list);
                    setTreeWorkspaces(tree);

                    setUser(user);
                    setAuthenticated(true);
                })
                .catch(error => {
                    if (error.response?.status === 422) {
                        Object.entries(error.response?.data?.errors)
                            .forEach(([key, message]) => {
                                setError(key, {
                                    type: 422,
                                    message: message[0],
                                });
                            });
                    } else newAlert('Erro ao tentar fazer login. Tente novamente mais tarde.');
                })
                .finally(resolve);
        });
    }

    function handleLogout(revoke = false, callback = () => null) {
        function logoutLocal() {
            localStorage.removeItem('authenticated');
            localStorage.removeItem('workspace');
            callback();
            setAuthenticated(false);
            setUser(null);
        }

        if (revoke) api.post('/logout').catch(e => console.error(e)).finally(logoutLocal);
        else {
            newAlert('Sessão expirada. Faça login novamente.', 'warning');
            logoutLocal();
        }
    }

    function refreshUser() {
        api.get('/user')
            .then(response => {
                let instance = response?.data?.data;
                const {tree, list} = getWorkspace(user);

                setWorkspaces(list);
                setTreeWorkspaces(tree);

                setUser(instance)
            })
            .catch(error => {
                if (error.response?.status === 401) handleLogout();
                else newAlert('Erro ao tentar atualizar o usuário. Tente novamente mais tarde.');
            });
    }

    function can(permission, root) {
        if (!user) return false;

        if (user.is_admin) return true;

        if (root) return user.permissions[root]?.includes(permission) ?? false;

        if (workspace === 0) {
            const fistWorkspace = Object.keys(workspaces)[1];

            return user.permissions[fistWorkspace]?.includes(permission) ?? false;
        }

        return user.permissions[workspace]?.includes(permission) ?? false;
    }

    return {
        authenticated,
        loading,
        user,
        setUser,
        can,
        handleLogin,
        handleLogout,
        refreshUser,
        redirect,
        setRedirect,
        treeWorkspaces,
        workspaces,
        workspace,
        setWorkspace: setWorkspacePersistent,
        selectWorkspace,
        setSelectWorkspace,
        loadingBackdrop,
        setLoadingBackdrop,
    };
}

export default useAuthProvider;

export {useAuthProvider}