import {useState} from 'react';
import {api} from '../services';
import Grid from '@mui/material/Grid2';
import {BtnShowPassword, ForgotPassword} from './';
import {Controller, useForm} from 'react-hook-form';
import {Button, Checkbox, FormControlLabel, Link, TextField, Typography} from '@mui/material';

function PasswordChange({newAlert, handleLogout, email}) {
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const handleForgotPasswordClick = () => setForgotPassword(true);
    const {
        reset,
        control,
        register,
        setError,
        handleSubmit,
        formState: {errors},
    } = useForm();

    function onSubmit(data) {
        setLoading(true);

        if (data.new_password === data.current_password) {
            setError('new_password', {
                type: 'password',
                message: 'A nova senha não pode ser igual a senha atual.',
            });

            setError('new_password_confirmation', {
                type: 'password',
                message: 'A nova senha não pode ser igual a senha atual.',
            })
            setLoading(false);
            return;
        }

        if (data.new_password !== data.new_password_confirmation) {
            setError('new_password_confirmation', {
                type: 'password',
                message: 'A nova senha e confirmação não conferem.',
            })
            setLoading(false);
            return;
        }

        api
            .patch('/change-password', {
                current_password: data.current_password,
                new_password: data.new_password,
                new_password_confirmation: data.new_password_confirmation,
                disconnect: data.disconnect,
            })
            .then(response => {
                newAlert(response?.data?.message, 'success')
                reset();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) {
                    Object.entries(error.response?.data?.errors)
                        .forEach(([key, message]) => {
                            setError(key, {
                                type: 422,
                                message: message[0],
                            });
                        });
                } else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar trocar senha. Tente novamente mais tarde.');
                }
            })
            .finally(() => setLoading(false));
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid
                    container
                    size={12}
                    className="p-2"
                    rowSpacing={{xs: 3, lg: 5}}
                >
                    <Grid
                        container
                        size={12}
                        className="justify-between items-center mt-3"
                        rowSpacing={0}
                        columnSpacing={4}
                    >
                        <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center">
                            <Typography className="font-medium" component="label" htmlFor="currentPassword">
                                Senha
                            </Typography>
                            <Link
                                className="text-left cursor-pointer	w-36 mb-2 lg:mb-0"
                                underline="none"
                                variant="body2"
                                onClick={handleForgotPasswordClick}
                            >
                                Esqueceu a senha?
                            </Link>
                        </Grid>
                        <Grid size={{xs: 12, lg: 6}} className="flex items-center">
                            <TextField
                                fullWidth
                                margin="normal"
                                id="currentPassword"
                                placeholder="Senha atual"
                                autoComplete="current-password"
                                type={showPassword ? 'text' : 'password'}
                                error={Boolean(errors?.current_password)}
                                helperText={errors?.current_password?.message}
                                slotProps={{
                                    input: {
                                        endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                                    },
                                }}
                                {...register('current_password', {
                                    required: 'A senha atual é obrigatória.',
                                    minLength: {
                                        value: 8,
                                        message: 'A senha atual precisa ter pelo menos 8 caracteres.',
                                    },
                                })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container size={12} className="justify-between items-center" rowSpacing={0} columnSpacing={4}>
                        <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center">
                            <Typography className="font-medium" component="label" htmlFor="newPassword">
                                Nova Senha
                            </Typography>
                            <Typography variant="body2" component="div" className="hidden lg:block">
                                Crie uma nova senha
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center items-end">
                            <TextField
                                fullWidth
                                margin="normal"
                                id="newPassword"
                                placeholder="Nova senha"
                                autoComplete="new-password"
                                type={showPassword ? 'text' : 'password'}
                                error={Boolean(errors?.new_password)}
                                helperText={errors?.new_password?.message}
                                slotProps={{
                                    input: {
                                        endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                                    },
                                }}
                                {...register('new_password', {
                                    required: 'A nova senha é obrigatória.',
                                    minLength: {
                                        value: 8,
                                        message: 'A nova senha precisa ter pelo menos 8 caracteres.',
                                    },
                                })}
                            />
                        </Grid>
                    </Grid>
                    <Grid container size={12} className="justify-between items-center" rowSpacing={0} columnSpacing={4}>
                        <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center">
                            <Typography className="font-medium" component="label" htmlFor="newPasswordConfirmation">
                                Confirmação
                            </Typography>
                            <Typography variant="body2" component="div" className="hidden lg:block">
                                Confirme a senha
                            </Typography>
                            <Controller
                                name="disconnect"
                                control={control}
                                defaultValue={true}
                                render={({field}) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value}
                                                onChange={(e) => field.onChange(e.target.checked)}
                                            />
                                        }
                                        label="Desconetar outros dispositivos"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid size={{xs: 12, lg: 6}} className="flex flex-col justify-center items-end">
                            <TextField
                                fullWidth
                                margin="normal"
                                id="newPasswordConfirmation"
                                placeholder="Confirmação"
                                autoComplete="new-password"
                                type={showPassword ? 'text' : 'password'}
                                error={Boolean(errors?.new_password_confirmation)}
                                helperText={errors?.new_password_confirmation?.message}
                                slotProps={{
                                    input: {
                                        endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                                    },
                                }}
                                {...register('new_password_confirmation', {
                                    required: 'A confirmação de senha é obrigatória.',
                                    minLength: {
                                        value: 8,
                                        message: 'A confirmação de senha precisa ter pelo menos 8 caracteres.',
                                    },
                                })}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container className="justify-end p-2 mt-1">
                    <Button
                        type="submit"
                        loading={loading}
                        disabled={Object.keys(errors).length > 0}
                    >
                        Salvar
                    </Button>
                </Grid>
            </form>
            <ForgotPassword
                forgotPassword={forgotPassword}
                setForgotPassword={setForgotPassword}
                email={email}
                disabled={true}
            />
        </>
    );
}

export default PasswordChange;

export {PasswordChange};
