import {
    Avatar,
    Backdrop,
    Box,
    CircularProgress,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import {useState} from 'react';
import {useAuth, useTitle} from '../hooks';
import {AppBar, Aside, Notifications} from './';
import {useLocation, useNavigate} from 'react-router';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';

function Header({open, openMobile, handleDrawerToggle, handleDrawerClose, handleDrawerTransitionEnd, isMobile}) {
    const {title} = useTitle();
    const local = useLocation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const {authenticated, handleLogout, user, loadingBackdrop, setLoadingBackdrop} = useAuth();

    const handleMenu = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleClickLogout = () => {
        handleClose();
        setLoadingBackdrop(true)
        handleLogout(true, () => setLoadingBackdrop(false));
    }

    if (local.pathname === '/login') return null;

    return (
        <>
            <AppBar
                open={open}
                position="sticky"
                authenticated={authenticated}
                sx={theme => ({background: theme.palette.primary.gradient,})}
            >
                <Toolbar className="pr-0 sm:pr-1">
                    {
                        authenticated && (
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                className="mr-2"
                                onClick={handleDrawerToggle}
                            >
                                <MenuIcon/>
                            </IconButton>
                        )
                    }
                    <Typography
                        variant="h6"
                        component="h1"
                        className="text-white grow"
                    >
                        {title}
                    </Typography>
                    {
                        authenticated && (
                            <Box>
                                <Notifications/>
                                <Tooltip title={user?.name}>
                                    <IconButton
                                        size="large"
                                        className="pl-0 sm:pl-3"
                                        aria-label="Abrir menu"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                    >
                                        <Avatar sx={{bgcolor: 'background.default', color: 'primary.light'}}>
                                            <PersonOutlineTwoToneIcon/>
                                        </Avatar>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <MenuItem
                                        className="py-3 px-4"
                                        onClick={() => {
                                            handleClose();
                                            navigate('../my-account');
                                        }}
                                    >
                                        <ListItemIcon>
                                            <AccountCircleIcon fontSize="small"/>
                                        </ListItemIcon>
                                        <ListItemText>Minha Conta</ListItemText>
                                    </MenuItem>
                                    <MenuItem className="py-3 px-4" onClick={handleClickLogout}>
                                        <ListItemIcon>
                                            <LogoutIcon fontSize="small"/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            Logout
                                        </ListItemText>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        )
                    }
                </Toolbar>
            </AppBar>
            {
                authenticated && (
                    <Aside
                        open={open}
                        isMobile={isMobile}
                        openMobile={openMobile}
                        handleDrawerClose={handleDrawerClose}
                        handleDrawerTransitionEnd={handleDrawerTransitionEnd}
                    />
                )
            }
            <Backdrop
                className="text-white"
                open={loadingBackdrop}
                sx={{zIndex: 2000,}}
            >
                <CircularProgress className="m-auto"/>
            </Backdrop>
        </>
    );
}

export default Header;

export {Header};