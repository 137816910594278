import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import BtnClose from './BtnClose';
import Grid from '@mui/material/Grid2';
import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {exportDashboard} from '../utils/exportDashboard';

function ExportDashboardModal({open, handleClose, company, data, dataAreas, equipments, startDate, endDate}) {
    const theme = useTheme();
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        reset,
        watch,
        control,
        handleSubmit,
    } = useForm({
        defaultValues: {
            charts: {
                volume: true,
                cost: true,
                consumption: true,
                area: true,
                reason: true,
            },
        }
    });

    const charts = watch('charts');
    const chartsActives = Object.values(charts).filter(type => type).length;

    function onSubmit() {
        setLoading(true);

        exportDashboard(charts, company, data, dataAreas, equipments, startDate, endDate).then(() => {
            setLoading(false)
        });
    }

    useEffect(() => {
        if (open) {
            reset({
                charts: {
                    volume: true,
                    cost: true,
                    consumption: true,
                    area: true,
                    reason: true,
                },
            });
        }
        // eslint-disable-next-line
    }, [open]);

    return (
        <Dialog
            maxWidth="sm"
            open={open}
            fullScreen={fullScreen}
            onClose={handleClose}
            slotProps={{
                paper: {
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit),
                },
            }}
        >
            <DialogTitle>
                <Box>
                    Exportar Dashboard
                </Box>
                <BtnClose onClick={handleClose}/>
            </DialogTitle>
            <DialogContent dividers className="px-10">
                <Grid container className="justify-between" spacing={2}>
                    <Grid container direction="column">
                        <Typography variant="h6" className="font-medium text-lg mb-3">
                            Gráficos que serão exportados:
                        </Typography>
                        <Controller
                            name="charts.volume"
                            control={control}
                            render={({field}) => (
                                <FormControlLabel
                                    sx={{cursor: charts.volume && chartsActives === 1 ? 'not-allowed' : 'pointer',}}
                                    label="Volume de lubrificantes"
                                    control={
                                        <Checkbox
                                            size="small"
                                            {...field}
                                            checked={field.value}
                                            readOnly={charts.volume && chartsActives === 1}
                                            sx={{
                                                cursor: charts.volume && chartsActives === 1 ? 'not-allowed' : 'pointer',
                                            }}
                                            onChange={e => {
                                                if (!charts.volume || chartsActives > 1) {
                                                    field.onChange(e.target.checked)
                                                }
                                            }}
                                        />
                                    }
                                    slotProps={{
                                        typography: {
                                            variant: 'body1',
                                            className: 'font-regular',
                                        },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="charts.cost"
                            control={control}
                            render={({field}) => (
                                <FormControlLabel
                                    sx={{cursor: charts.cost && chartsActives === 1 ? 'not-allowed' : 'pointer',}}
                                    label="Custo dos lubrificantes"
                                    control={
                                        <Checkbox
                                            size="small"
                                            {...field}
                                            checked={field.value}
                                            readOnly={charts.cost && chartsActives === 1}
                                            sx={{
                                                cursor: charts.cost && chartsActives === 1 ? 'not-allowed' : 'pointer',
                                            }}
                                            onChange={e => {
                                                if (!charts.cost || chartsActives > 1) {
                                                    field.onChange(e.target.checked);
                                                }
                                            }}
                                        />
                                    }
                                    slotProps={{
                                        typography: {
                                            variant: 'body1',
                                            className: 'font-regular',
                                        },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="charts.consumption"
                            control={control}
                            render={({field}) => (
                                <FormControlLabel
                                    sx={{cursor: charts.consumption && chartsActives === 1 ? 'not-allowed' : 'pointer',}}
                                    label="Consumo de Equipamentos"
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={field.value}
                                            readOnly={charts.consumption && chartsActives === 1}
                                            sx={{
                                                cursor: charts.consumption && chartsActives === 1 ? 'not-allowed' : 'pointer',
                                            }}
                                            onChange={e => {
                                                if (!charts.consumption || chartsActives > 1) {
                                                    field.onChange(e.target.checked);
                                                }
                                            }}

                                        />
                                    }
                                    slotProps={{
                                        typography: {
                                            variant: 'body1',
                                            className: 'font-regular',
                                        },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="charts.area"
                            control={control}
                            render={({field}) => (
                                <FormControlLabel
                                    sx={{cursor: charts.area && chartsActives === 1 ? 'not-allowed' : 'pointer',}}
                                    label="Área dos Lubrificantes"
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={field.value}
                                            readOnly={charts.area && chartsActives === 1}
                                            sx={{
                                                cursor: charts.area && chartsActives === 1 ? 'not-allowed' : 'pointer',
                                            }}
                                            onChange={e => {
                                                if (!charts.area || chartsActives > 1) {
                                                    field.onChange(e.target.checked);
                                                }
                                            }}

                                        />
                                    }
                                    slotProps={{
                                        typography: {
                                            variant: 'body1',
                                            className: 'font-regular',
                                        },
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name="charts.reason"
                            control={control}
                            render={({field}) => (
                                <FormControlLabel
                                    sx={{cursor: charts.reason && chartsActives === 1 ? 'not-allowed' : 'pointer',}}
                                    label="Razão do Consumo"
                                    control={
                                        <Checkbox
                                            size="small"
                                            checked={field.value}
                                            readOnly={charts.reason && chartsActives === 1}
                                            sx={{
                                                cursor: charts.reason && chartsActives === 1 ? 'not-allowed' : 'pointer',
                                            }}
                                            onChange={e => {
                                                if (!charts.reason || chartsActives > 1) {
                                                    field.onChange(e.target.checked);
                                                }
                                            }}

                                        />
                                    }
                                    slotProps={{
                                        typography: {
                                            variant: 'body1',
                                            className: 'font-regular',
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className="justify-between px-10">
                <Button
                    color="error"
                    variant="outlined"
                    onClick={handleClose}
                >
                    Cancelar
                </Button>
                <Button
                    type="submit"
                    loading={loading}
                    variant="outlined"
                >
                    Baixar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ExportDashboardModal;

export {ExportDashboardModal};