import {styled} from '@mui/system';
import {Fade as FadeMUI} from '@mui/material';
import adjustWith from '../utils/adjustWith';

const Fade = styled(FadeMUI, {
    shouldForwardProp: prop => !['open', 'authenticated'].includes(prop)
})(adjustWith);

export default Fade;

export {Fade};