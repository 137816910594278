const lubricantTypes = [
    {label: 'Graxa Lubrificante', acronym: 'Graxa'},
    {label: 'Fluido de Transmissão', acronym: 'Fluido TRA'},
    {label: 'Fluido de Corte', acronym: 'Fluido COR'},
    {label: 'Fluido de Freio', acronym: 'Fluido FRE'},
    {label: 'Fluido Dielétrico', acronym: 'Fluido DIE'},
    {label: 'Fluido Refrigerante', acronym: 'Fluido REF'},
    {label: 'Óleo Hidráulico Mineral', acronym: 'Óleo HMI'},
    {label: 'Óleo Hidráulico Sintético', acronym: 'Óleo HSI'},
    {label: 'Óleo Lubrificante Mineral', acronym: 'Óleo LMI'},
    {label: 'Óleo Lubrificante Monoviscoso', acronym: 'Óleo LMO'},
    {label: 'Óleo Lubrificante para Guias e Barramentos', acronym: 'Óleo LGB'},
    {label: 'Óleo Lubrificante para Transmissão', acronym: 'Óleo LTR'},
    {label: 'Óleo Lubrificante Sintético', acronym: 'Óleo LSI'},
    {label: 'Óleo Mineral', acronym: 'Óleo MIN'},
    {label: 'Óleo para Bomba de Vácuo', acronym: 'Óleo BVA'},
    {label: 'Óleo para Turbinas', acronym: 'Óleo TUR'},
    {label: 'Oléo para Transmissão Universal', acronym: 'Óleo TRU'},
    {label: 'Spray Antioxidante', acronym: 'Spray ANT'},
    {label: 'Óleo Penetrante', acronym: 'Óleo PEN'},
    {label: 'Óleo Refrigerante', acronym: 'Óleo REF'},
    {label: 'Óleo SAE', acronym: 'Óleo SAE'},
];

export default lubricantTypes;

export {lubricantTypes};