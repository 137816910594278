import {moneyFormatter, toLocaleString} from './';

function numberFormatter(value, money = false) {
    if (!value && value !== 0) return '';

    function simplify(order) {
        let result = value / order;

        result = result.toFixed(2)
            .replace(/\.00$/, '')
            .replace(/\.([0-9])0$/, '.$1')
            .replace('.', ',');

        return result;
    }

    if (value >= 1000000000) {
        const result = simplify(1000000000);

        return money ? `${moneyFormatter(result)} Bi` : `${result} Bi`;
    } else if (value >= 1000000) {
        const result = simplify(1000000);

        return money ? `${moneyFormatter(result)} Mi` : `${result} Mi`;
    } else if (value >= 1000) {
        const result = simplify(1000);

        return money ? `${moneyFormatter(result)} mil` : `${result} mil`;
    }

    return money ? moneyFormatter(value) : toLocaleString(value, 1);
}

export default numberFormatter;

export {numberFormatter};