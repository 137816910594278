import {TableCell, TablePagination as TablePaginationMUI} from '@mui/material';

const TablePagination = ({
                             component = TableCell,
                             rowsPerPageOptions = [10, 25, 50, 100],
                             setPerPage,
                             setPage,
                             ...props
                         }) => {
    const handleChangePage = (event, newPage) => setPage(newPage);

    const handleChangeRowsPerPage = event => {
        setPage(0);
        setPerPage(parseInt(event.target.value, 10));
    };

    return (
        <TablePaginationMUI
            {...props}
            component={component}
            className="px-0 border-b-0"
            rowsPerPageOptions={rowsPerPageOptions}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={props => ''}
        />
    );
}

export default TablePagination;

export {TablePagination};