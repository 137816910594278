import axios from 'axios';
import env from 'react-dotenv';
import {Cookies} from 'react-cookie';

const cookies = new Cookies();

const api = axios.create({
    withCredentials: true,
    withXSRFToken: true,
    baseURL: env?.API_URL,
    headers: {
        Accept: 'application/json',
    },
});

api.interceptors.request.use(async config => {
    if (config.url === '/csrf') return config;

    const xsrf = cookies.get('XSRF-TOKEN');

    if (!xsrf) await api.get('/csrf');

    return config;
});

api.interceptors.response.use(null, async error => {
    if (error?.response?.status === 419) {
        await api.get('/csrf')
        return await api.request(error.config);
    }

    return Promise.reject(error);
});

export default api;

export {api};